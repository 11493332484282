<template>
  <div>
    <button class="d-none" data-toggle="modal" data-target="#TournamentEditGame"
      ref="openModalTournamentEditGame"></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="TournamentEditGame">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
             <div>
              <h6 class="modal-title m-0">Edit Game</h6>
              <div class="products-list-shim p-0" v-if="showLoader == 1">
                <content-placeholders :rounded="true">
                  <content-placeholders-text :lines="1"/>
                </content-placeholders>
              </div>
              <span v-else class="text-capitalize">{{tournamentData?.event?.eventName}} - {{tournamentData?.tournamentName}}</span>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              ref="closeModalTournamentEditGame">
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="row no-gutters">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="typo__label" for="ajaxsingle">Game Type</label>
                  <select class="form-control form-control-lg" id="gameType" v-model="game_type" @change="errorNull">
                    <option value="" selected>Select game type</option>
                    <option value="1">Pool</option>
                    <option value="2">Bracket</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="name">Game Name</label>
                  <input id="name" class="form-control" v-model="name" spellcheck="false" autocomplete="off" trim
                    type="text" required @input="errorNull" />
                </div>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label class="typo__label">Team 1</label>
                  <select class="form-control form-control-lg" id="teamOne" v-model="teamOne" @change="errorNull"
                    @input="errorNull" :disabled="singleGame.game_live === 1 || singleGame.status === 2">
                    <option value="" selected>No Team One</option>
                    <option v-for="option in tournamentTeams" :value="option.team.id" :key="option.id">
                      {{ option.team.team_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 pl-md-2">
                <div class="form-group">
                  <label class="typo__label">Team 2</label>
                  <select class="form-control form-control-lg" id="teamTwo" v-model="teamTwo" @change="errorNull"
                    @input="errorNull" :disabled="singleGame.game_live === 1 || singleGame.status === 2">
                    <option value="" selected>No Team Two</option>
                    <option v-for="option in remainingTeams" :value="option.team.id" :key="option.id">
                      {{ option.team.team_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label class="typo__label">Umpire</label>
                  <select class="form-control form-control-lg" id="umpire" v-model="umpire" @change="errorNull"
                    :disabled="isLoadingUmpire">
                    <!-- Default option always present -->
                    <option value="0" selected>No Umpire Selected</option>

                    <!-- Only display umpires if they are loaded and available -->
                    <option v-for="option in tournamentUmpire" :value="option.umpire.id" :key="option.umpire.id">
                      {{ option.umpire.name }}
                    </option>
                  </select>

                  <!-- Loader for umpire loading -->
                  <div class="shop-products-img-loader" v-if="isLoadingUmpire">
                    <div class="lds-dual-ring-media"></div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 pl-md-2">
                <div class="form-group">
                  <label for="startDateCheck">Start Date</label>
                  <date-picker :open="open" @close="open = false" :editable="false" :clearable="false"
                    @click.native="handleClickStart" v-model="startDate" valueType="format" format="YYYY-MM-DD"
                    placeholder="yyyy-mm-dd" @change="checkdate">
                    <i slot="icon-calendar" class="cursor-pointer">
                      <img src="../../../assets/images/calendar-input.png" />
                    </i>
                  </date-picker>
                </div>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="startTimeCheck">Start Time</label>
                  <div class="input-group time" id="startTimeCheck" data-target-input="nearest">
                    <vue-timepicker id="startTime" class="time-input" placeholder="10:00 AM" format="hh:mm A"
                      required="required" v-model="startTime" v-on:change="checkTime" close-on-complete
                      hide-clear-button>
                      <template v-slot:icon>
                        <img src="../../../assets/images/clock-input.png" />
                      </template>
                    </vue-timepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="row my-2">
              <div class="col-sm-12 col-md-6 pl-md-2">
                <div class="position-relative">
                  <label class="typo__label" for="ajaxsingle">Park Name</label>
                  <button @click="openPark" class="button-overlay"></button>
                  <multiselect ref="multiselectParkName" v-model="partName" id="ajaxsingle" label="park_name"
                    track-by="id" placeholder="Type to search" open-direction="bottom" :options="parkNameOptions"
                    :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false"
                    :close-on-select="true" :options-limit="300" :show-no-results="false" :hide-selected="true"
                    @search-change="getGamePark" @input="checkParkField" @select="dispatchAction"
                    @open="isOpenPark = true">
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">&nbsp;<span> {{ option.park_name }}</span><span class="custom__remove"
                          v-on:click="remove(option)">❌</span></span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="parkNameOptions.length"
                        @mousedown.prevent.stop="clearAll(props.search)"></div>
                    </template>
                    <span slot="noResult">Oops! No Park found. Consider changing the search query.</span>
                    <span slot="noOptions">No Park Found</span>
                    <template slot="option" slot-scope="{ option }">
                      <div class="option__desc">
                        <span> {{ option.park_name }}</span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 pl-md-2 mt-2 mt-md-0">
                <div class="">
                  <label class="typo__label" for="ajaxsingle">Field Name</label>
                  <button @click="openField" class="button-overlay"></button>
                  <multiselect ref="multiselectParkField" v-model="parkField" id="ajaxsingle" label="field_name"
                    track-by="id" placeholder="Type to search" open-direction="bottom" :options="fieldNameOptions"
                    :searchable="true" :loading="isLoadingField" :internal-search="false" :clear-on-select="false"
                    :close-on-select="true" :options-limit="300" :show-no-results="false" :hide-selected="true"
                    @search-change="getParkField" @input="errorNull" @select="dispatchfieldAction"
                    @open="isOpenField = true">
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">&nbsp;<span> {{ option.field_name }}</span><span class="custom__remove"
                          v-on:click="remove(option)">❌</span></span>
                    </template>
                    <span slot="noResult">Oops! No Field found. Consider changing the search query.</span>
                    <span slot="noOptions">No Field Found</span>
                    <template slot="option" slot-scope="{ option }">
                      <div class="option__desc">
                        <span> {{ option.field_name }}</span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="note">Game Notes</label>
              <textarea type="text" class="form-control message" required rows="5" id="note" v-model="note"></textarea>
            </div>

            <a class="d-none" data-toggle="modal" data-target="#ModalPARK" ref="callPark" @click="closeMain">
              Add New Park
            </a>
            <a class="d-none" data-toggle="modal" data-target="#ModalField" ref="callField" @click="newField">
              Add New Field
            </a>

            <a class="d-none" data-toggle="modal" data-target="#gameBulkReplaceModal" id="openBulkReplaceModal"
              href="#"></a>
          </div>

          <div class="event-btns-wrapper p-3 justify-content-end">
            <button v-if="this.isDisabled == false" type="button" class="btn btn-primary w-100" block
              @click="updateGame(0)" :disabled="isLoadingArray[0]">
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button v-else-if="this.isDisabled == true" type="button" class="btn btn-primary-disabled w-100"
              :disabled="isLoadingArray[0]">
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <NewPark />
    <NewField />
  </div>
</template>
<script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import Multiselect from "vue-multiselect";
import NewPark from "../../Teams/Games/AddPark";
import NewField from "../../Teams/Games/AddField";
export default {
  name: "TournamentEditGameModal",
  components: {
    VueTimepicker,
    Multiselect,
    NewPark,
    NewField,
  },
  data() {
    return {
      name: "",
      teamOne: "",
      umpire: "",
      teamTwo: "",
      open: false,
      isLoadingArray: [],
      singleGame: [],
      selectedParkId: "",
      userName: localStorage.getItem("userName"),
      isDisabled: true,
      partName: "",
      parkField: "",
      exactStartTime: "10:00 AM",
      exactStartDate: "",
      startDate: "",
      startTime: "10:00 AM",
      note: "",
      game_type: "",
      startDateError: "",
      exactDateformat: "",
      timeError: "",
      parkNameOptions: [],
      fieldNameOptions: [],
      tournamentUmpire: [],
      tournamentTeams: [],
      teamSelect: [],
      teamLoader: true,
      isLoading: false,
      isLoadingField: false,
      isLoadingUmpire: true,
      tabsTo: 0,
      changePark: 0,
      changeField: 0,
      showLoader: 0,
      parkId: "",
      isOpenPark: false,
      isOpenField: false,
    };
  },
  props: {
    tournamentData: {
      typeof: Object,
    }
  },
  watch: {
    tournamentData: {
      immediate: true, // Trigger the watcher immediately on component mount
      handler(newVal) {
        console.log('Tournament Data updated:', newVal);
      },
    },
  },
  computed: {
    // remainingTeams() {
    //   return this.tournamentTeams.filter(
    //     team => team.team.id !== this.teamOne
    //   );
    // }
    remainingTeams: {
      get() {
        return this.tournamentTeams.filter(
          team => team.team.id !== this.teamOne);
      },
      set(value) {
        this.teamSelect = value;
      }
    }
  },
  methods: {
     async getTournamentName() {
      // var url = window.location.pathname;
      // var segment_array = url.split("/");
      // const tournamentGuid = segment_array.pop();
      
      const tournamentGuid = this.singleGame?.tournament?.guid;
      this.showLoader = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}tournaments/getSingleTournament`,
          {tournament_guid: tournamentGuid}
        );

        if (response.data.statusCode === 200) {
            this.tournamentData = response.data.data;
            // this.$root.$emit("tournmentSingleGameForTab", this.tournamentData )
            // this.$root.$emit("tournmentSingleGameForGame", this.tournamentData )
            this.showLoader = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
      } finally {
        this.showLoader = 0;
      }
  },
    dataReset() {
      if (this.tabsTo == 0) {
        this.isDisabled = true;
        this.name = "";
        this.teamOne = "";
        this.umpire = "";
        this.teamTwo = "";
        this.partName = "";
        this.parkField = "";
        this.exactStartTime = "10:00 AM";
        this.exactStartDate = "";
        this.startDate = "";
        this.startTime = "10:00 AM";
        this.note = "";
        this.game_type = "";
        this.startDateError = "";
        this.exactDateformat = "";
        this.timeError = "";
        this.parkNameOptions = [];
        this.fieldNameOptions = [];
      } else {
        // this.partName = "";
        this.parkField = "";
      }
    },
    filterTeam() {
      this.remainingTeams = this.tournamentTeams.filter(
        team => team.team.id !== this.teamOne
      );
    },
    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    openPark() {
      this.getGamePark();
      setTimeout(() => {
        this.$refs.multiselectParkName.activate();
      }, 1000);
    },

    openField() {
      this.getParkField();
      setTimeout(() => {
        this.$refs.multiselectParkField.activate();
      }, 1000);
    },

    checkdate: function () {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Start Date
      if (this.startDate.length == 10) {
        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];

        var varDate = startMonth + "-" + startDay + "-" + startYear; //dd-mm-YYYY
        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.exactStartDate = month[startMonth - 1] + " " + startDay + ", ";
      }

      this.checkTimeDate();
    },
    dispatchAction(actionName) {
      this.parkField = "";
      this.fieldNameOptions = [];
      switch (actionName.park_name) {
        case "Add Park":
          var tabs = 0;
          this.$root.$emit("AddParkInitiate", tabs);
          var elem = this.$refs.callPark;
          elem.click();
          break;
        default:
          // Assume that the actionName contains the selected park's ID
          this.selectedParkId = actionName.id;
          break;
      }
    },
    errorNull: function () {
      if (
        this.name == "" ||
        this.exactStartDate == "" ||
        this.exactStartTime == "" ||
        // (this.teamOne !== "" && this.teamTwo == "") || 
        // (this.teamOne == "" && this.teamTwo !== "") ||
        this.game_type == "" ||
        // this.teamOne == "" ||
        // this.teamTwo == "" ||
        // this.umpire == "" ||
        this.partName == ""
        // || this.teamTwo == ""
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    closeMain() {
      this.$root.$emit("AddPark");
      var elem = this.$refs.closeModalTournamentEditGame;
      elem.click();
    },
    newField() {
      this.$root.$emit("AddField");
      var elem = this.$refs.closeModalTournamentEditGame;
      elem.click();
    },
    getGamePark() {
      this.isLoading = true;

      // Use this.tournamentData.event.id if it exists, otherwise use this.singleGame.event_id
      const eventId = this.tournamentData?.event?.id || this.singleGame?.event_id;

      axios
        .get(
          `${process.env.VUE_APP_API_URL}associationEvent/getEventPlayingFacility?event_id=${eventId}`
        )
        .then((response) => {
          this.parkNameOptions = response.data.data.parkDetails;
        })
        .catch((error) => {
          console.error("Error fetching park details:", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getParkField(searchQuery) {
      this.isLoadingField = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getParkField?park_id=${this.parkId
          }&search=${searchQuery ? searchQuery : ""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.fieldNameOptions = response.data.data.fields;
          this.isLoadingField = false;
        });
    },
    getTournamentUmpire() {
      if (this.singleGame && this.tournamentData.event.id) {
        this.isLoadingUmpire = true;
        axios
          .get(
            `${process.env.VUE_APP_API_URL}associationEvent/getUmpiresForDropDown?event_id=${this.tournamentData.event.id}`,
            {}
          )
          .then((response) => {
            this.tournamentUmpire = response.data.data.umpires;
            this.isLoadingUmpire = false;
          })
          .catch((error) => {
            console.error("Error fetching tournament umpires:", error);
            this.isLoadingUmpire = false;
          });
      }
    },
    getTournamentUmpireFromScoring() {
      if (this.singleGame) {
        this.isLoadingUmpire = true;
        axios
          .get(
            `${process.env.VUE_APP_API_URL}associationEvent/getUmpiresForDropDown?event_id=${this.singleGame.event.id}`,
            {}
          )
          .then((response) => {
            this.tournamentUmpire = response.data.data.umpires;
            this.isLoadingUmpire = false;
          })
          .catch((error) => {
            console.error("Error fetching tournament umpires:", error);
            this.isLoadingUmpire = false;
          });
      }
    },
    getTournamentTeams() {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const tournamentGuid = segment_array.pop();
      this.teamLoader = true;
      // Create a FormData object
      const formData = new FormData();
      formData.append('tournament_guid', tournamentGuid);

      axios
        .post(
          `${process.env.VUE_APP_API_URL}tournaments/teams/getTournamentTeamsForDropDown`,
          formData, {})
        .then((response) => {
          this.tournamentTeams = response.data.data;
          this.teamLoader = false;
        });
    },
    getTournamentTeamsFromScoring() {

      const formData = new FormData();
      formData.append('tournament_guid', this.singleGame.tournament.guid);

      axios
        .post(
          `${process.env.VUE_APP_API_URL}tournaments/teams/getTournamentTeamsForDropDown`,
          formData, {})
        .then((response) => {
          this.tournamentTeams = response.data.data;
          this.teamLoader = false;
        });
    },

    checkParkField: function () {
      this.parkId = this.partName.id;
      this.getParkField();
      this.errorNull();
    },
    dispatchfieldAction(actionName) {
      switch (actionName.field_name) {
        case "Add field":
          var tabs = 3;
          this.$root.$emit("AddFieldInitiate", {
            tabs,
            park_id: this.partName.id,
          });
          var elem = this.$refs.callField;
          elem.click();
          break;
      }
    },
    checkTime: function () {
      if (this.startTime != "") {
        var timeSplit = this.startTime.split(":"),
          hours,
          minutes;
        hours = timeSplit[0];
        minutes = timeSplit[1];
        if (hours > 12) {
          hours -= 12;
        } else if (hours < 12) {
          if (hours == 0) {
            hours = 12;
          }
        }
        this.exactStartTime = hours + ":" + minutes;
        this.timeError = "";
        this.isDisabledGame = false;
      }

      this.checkTimeDate();
    },
    closePark() {
      this.tabsTo = 0;
      var start_time = document.getElementById("startTime");
      if (start_time.value != "") {
        start_time.value = "";
      }
    },
    checkTimeDate() {
      this.startDateError = this.exactStartDate != "" ? "" : "on";
      if (this.exactStartTime != "" && this.exactStartDate != "") {
        this.exactDateformat = this.exactStartDate + " " + this.exactStartTime;
      }
      this.errorNull();
    },

    async updateGame(index) {
      // Start the loader and disable the game controls
      this.showLoaderGame = true;
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, true);

      // Prepare the form data for the POST request
      var formData = new FormData();
      formData.append("game_name", this.name ? this.name : "");
      formData.append("team_1_id", this.teamOne ? this.teamOne : "");
      formData.append("event_id", (this.tournamentData && this.tournamentData.event) ? this.tournamentData.event.id : this.singleGame.event.id);
      formData.append("team_2_id", this.teamTwo ? this.teamTwo : "");
      formData.append("start_date", this.startDate);
      formData.append("start_time", this.startTime);
      formData.append("park_id", this.partName.id);
      formData.append("game_type", this.game_type ? this.game_type : "");
      formData.append("comments", this.note ? this.note : "");
      if (this.parkField && this.parkField.id != "") {
        formData.append("field_id", this.parkField.id ? this.parkField.id : null);
      }
      formData.append("game_id", this.singleGame.id);
      if(this.umpire === 0){
        formData.append("umpire_id", "");
      } else {
        formData.append("umpire_id", this.umpire ? this.umpire : "");
      }

      try {
        // Send the POST request to the server
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}tournaments/editTournamentGame`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.statusCode === 404 ) {
          this.$refs.closeModalTournamentEditGame.click();
          this.$set(this.isLoadingArray, index, false);
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
        } else if(response.data.statusCode === 403) {
          this.$alert(response.data.message, 'Error');
          this.$root.$emit("RefreshTournamentGames");
          this.tabsTo = 0;
          if (this.$refs.closeModalTournamentEditGame) {
            this.$refs.closeModalTournamentEditGame.click();
          }
        } else {
          this.$root.$emit("RefreshTournamentGames");
          this.tabsTo = 0;
          if (this.$refs.closeModalTournamentEditGame) {
            this.$refs.closeModalTournamentEditGame.click();
          }
        }
      } catch (error) {
        console.error("Error updating game: ", error);
      } finally {
        this.showLoaderGame = false;
        this.isDisabledGame = false;
        this.$set(this.isLoadingArray, index, false);

      }
    },


    formatTime(timeString) {
      const [hours, minutes] = timeString.split(":");
      let formattedHours = parseInt(hours, 10) % 12 || 12; // Convert hours to 12-hour format
      const meridian = parseInt(hours, 10) >= 12 ? "PM" : "AM"; // Determine meridian (AM or PM)
      formattedHours = formattedHours < 10 ? "0" + formattedHours : formattedHours; // Add leading zero if necessary
      return `${formattedHours}:${minutes} ${meridian}`;
    },
    checkUpdate() {
      console.log("10:00 AM")
      this.isDisabledGame = true;
      this.showLoaderGame = 0;
      this.startTime = this.singleGame.start_time;
      this.name = this.singleGame.game_name;
      this.teamOne = this.singleGame.team_1_id;
      this.teamTwo = this.singleGame.team_2_id;
      if(this.singleGame.umpire_id){
        this.umpire = this.singleGame.umpire_id 
      } else {
        this.umpire = 0;
      }
      this.game_type = this.singleGame.game_type;
      this.partName = this.singleGame.park;
      this.parkField = this.singleGame.field;
      this.parkId = this.singleGame.park_id;
      this.previousName = this.singleGame;
      this.exactStartTime = "10:00 AM";
      this.exactStartDate = "";
      this.startDate = this.singleGame.start_date;
      this.note = this.singleGame.comments;
      this.startDateError = "";
      this.exactDateformat = "";
      this.timeError = "";
      this.parkNameOptions = [];
      this.country = this.singleGame.opponent_country;
      this.region = this.singleGame.opponent_state;
      this.opponentCity = this.singleGame.opponent_city;
      this.getTournamentUmpire();
      this.getTournamentTeams();
      this.checkdate();
      this.checkTime();
      this.errorNull();
    },
    checkUpdate1() {
      console.log("10:00 AM")
      this.isDisabledGame = true;
      this.showLoaderGame = 0;
      this.startTime = this.singleGame.start_time;
      this.name = this.singleGame.game_name;
      this.teamOne = this.singleGame.team_1_id;
      this.teamTwo = this.singleGame.team_2_id;
      if(this.singleGame.umpire_id){
        this.umpire = this.singleGame.umpire_id 
      } else {
        this.umpire = 0;
      }
      this.game_type = this.singleGame.game_type;
      this.partName = this.singleGame.park;
      this.parkField = this.singleGame.field;
      this.parkId = this.singleGame.park_id;
      this.previousName = this.singleGame;
      this.exactStartTime = "10:00 AM";
      this.exactStartDate = "";
      this.startDate = this.singleGame.start_date;
      this.note = this.singleGame.comments;
      this.startDateError = "";
      this.exactDateformat = "";
      this.timeError = "";
      this.parkNameOptions = [];
      this.country = this.singleGame.opponent_country;
      this.region = this.singleGame.opponent_state;
      this.opponentCity = this.singleGame.opponent_city;
      this.getTournamentUmpireFromScoring();
      this.getTournamentTeamsFromScoring();
      this.checkdate();
      this.checkTime();
      this.errorNull();
    },
  },
  mounted() {
    this.$root.$on("openTournamentEditGameModal1", (data) => {
      console.log(data, "addedPark")
      this.singleGame = data;
      this.startDateError = "";
      this.checkUpdate();
      var elem = this.$refs.openModalTournamentEditGame;
      if (elem) {
        elem.click();
        this.getTournamentName();
      }
    });
    this.$root.$on("openTournamentEditGameModal2", (data) => {
      console.log(data, "addedPark")
      this.singleGame = data;
      this.startDateError = "";
      this.checkUpdate1();
      var elem = this.$refs.openModalTournamentEditGame;
      if (elem) {
        elem.click();
        this.getTournamentName();
      }
    });
    this.$root.$on("addedPark", (Tabs) => {
      this.tabsTo = Tabs;
      this.getGamePark();
      var elem = this.$refs.openModalTournamentEditGame;
      elem.click();
    });
    this.$root.$on("editedGameField", (Tabs) => {
      this.tabsTo = Tabs;
      this.parkField = "";
      this.getParkField();
      var elem = this.$refs.openModalTournamentEditGame;
      elem.click();
    });
    this.$root.$on("checkMain", () => {
      this.closeMain();
    });
  },
};
</script>
<style scoped>
.lds-dual-ring-media:after {
  width: 20px;
  height: 20px;
}

.shop-products-img-loader {
  position: absolute;
  top: 68%;
  left: 90% !important;
  z-index: 99;
}



.multiselect__spinner {
  position: absolute;
  right: 15px;
  top: 62%;
  transform: translate(-1px, -50%);
  width: 48px;
  height: 41px;
  background: inherit;
  display: block;
  z-index: 9;

}

.form-group select {
  font-size: 14px !important;
  min-height: 45px !important;
  display: block !important;
  padding: 8px 40px 8px 8px !important;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
  background: #fff !important;
  border-radius: 5px !important;
}


.button-overlay {
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  transform: translate(0, -100%) !important;
  background: transparent !important;
  border: none !important;
  z-index: 99 !important;
  height: 50px !important;
}

@media screen and (max-width: 768px) {
  .modal-dialog .modal-content .modal-body{
    max-height: calc(100vh - 200px);
    overflow: scroll;
  }
}
</style>