import { render, staticRenderFns } from "./TournamentEditGameModal.vue?vue&type=template&id=274e1810&scoped=true&"
import script from "./TournamentEditGameModal.vue?vue&type=script&lang=js&"
export * from "./TournamentEditGameModal.vue?vue&type=script&lang=js&"
import style0 from "./TournamentEditGameModal.vue?vue&type=style&index=0&id=274e1810&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274e1810",
  null
  
)

export default component.exports