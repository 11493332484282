<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#EditMessage"
      ref="openModalMessageEdit"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="EditMessage">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Edit Message</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalMessage"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="subject">Subject</label>
              <input
                id="subject"
                class="form-control"
                v-model="subject"
                spellcheck="false"
                autocomplete="off"
                trim
                type="text"
                required
                @input="errorNull"
              />
            </div>
            <div class="form-group">
              <label for="message">Message Body</label>
              <textarea
                type="text"
                class="form-control message"
                required
                rows="5"
                id="message"
                v-model="message"
                @input="errorNull"
              ></textarea>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <p>Turn on replies for this message</p>
                <toggle-button
                  type="checkbox"
                  class="m-0 p-0  d-flex justify-content-end"
                  id="allowReplies"
                  :width="34"
                  :sync="true"
                  :value="allowReplies"
                  v-model="allowReplies"
                />
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-between">
            <button class="btn btn-danger w-25" @click="deleteMessage()">
              <span>Delete</span>
            </button>
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="editMessage(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EditMessageModal",
  props: {
    messageData: Object,

    association_id: {
      type: Number,
    }, 
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      subject: "",
      message: "",
      topic_id: null,
      allowReplies: false,
      message_id: null,
    };
  },
  methods: {
    errorNull() {
      if (
        this.message === "" ||
        this.subject === ""
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    closeModal(){
      var elem = this.$refs.closeModalMessage;
      elem.click();
    },

    editMessage(index) {
      this.$set(this.isLoadingArray, index, true);

      const apiUrl = `${process.env.VUE_APP_API_URL}associationEvent/editMessage`;
      const requestData = {
        association_id: this.association_id,
        message_id: this.message_id,
        message_subject: this.subject,
        message_body: this.message,
        topic_id: this.topic_id,
        allow_replies: this.allowReplies,
      };

      axios
        .post(apiUrl, requestData)
        .then((response) => {
          // Handle the response based on the statusCode
          const { statusCode, message } = response.data;

          if (statusCode === 200) {
            // Successfully edited the message
            this.$set(this.isLoadingArray, index, false);
            this.closeModal();
            this.$root.$emit("editMessageChange");
            console.log("Message edited successfully:", message);
          } else if (statusCode === 404) {
              this.closeModal();
              this.$alert(message, 'Error');
              this.$router.push({ name: "Teams" });
              this.$set(this.isLoadingArray, index, false);
            } else {
            // Handle the case where statusCode is not 200
            console.error("Failed to edit message:", message);
            this.$set(this.isLoadingArray, index, false);
          }
        })
        .catch((error) => {
          // Handle any unexpected errors 
          console.error("Failed to edit message:", error);
          this.$set(this.isLoadingArray, index, false);
        });
    },
    deleteMessage() {
      // Retrieve the event_guid from localStorage
      const eventGuid = this.messageData.event.guid;
      const apiUrl = `${process.env.VUE_APP_API_URL}associationEvent/deleteMessage`;
      const requestData = {
        message_id: this.message_id,
        association_id: this.association_id,
      };

      this.$confirm(
        "About to delete the message. All replies against the message will also be deleted. Continue?"
      ).then(() => {
        axios
          .post(apiUrl, requestData)
          .then((response) => {
            // Check the statusCode in the response
            if (response.data.statusCode === 200) {
              this.closeModal();
              this.$router.replace({
                name: "AssociationSingleEventDetail",
                params: { id: eventGuid },
              });
            } else if (response.data.statusCode === 404) {
              this.closeModal();
              this.$alert(response.data.message, 'Error');
              this.$router.push({ name: "Teams" });
            } else {
              // Handle specific status codes here
              console.error("Error:", response.data.message);
              this.$alert(response.data.message || "Failed to delete message.");
            }
          })
          .catch((error) => {
            // Handle network errors or unexpected response structures
            console.error("Failed to delete message:", error);
            this.$alert("An error occurred while deleting the message.");
          });
      });
    }

  },
  watch: {
    // Watch for changes in subject, message, and allowReplies
    subject() {
      this.errorNull();
    },
    message() {
      this.errorNull();
    },
  },
  mounted() {
    this.$root.$on("editMessageModal", (messageData) => {
      this.topic_id = messageData.topic_id;
      this.message_id = messageData.id;
      this.subject = messageData.message_subject;
      this.message = messageData.message_body;
      this.allowReplies = messageData.allow_replies == 1 ? true : false;
      var elem = this.$refs.openModalMessageEdit;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
