<template>
  <div>
    <div>
      <MyEventsLiveCard />
    </div>

    <div class="team-info-wrap">
      <div class="row m-0">
        <!-- <div
          class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
        >
          <input
            class=""
            id="eventType"
            v-model="filter.name"
            @input="getFilters()"
            placeholder="Enter Event Name"
          />
          <span
            :style="{ display: isLoading ? 'none' : 'block' }"
            v-if="filter.name"
            class="clear-icon"
            @click="clearName()"
            ><img src="../../assets/cross-icon.png"
          /></span>
        </div> -->
        <div class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
          <select class="" v-model="filter.association" @change="getFilters()">
            <option value="" selected>Select Association</option>
            <option v-for="option in associationOptions" :value="option" :key="option">
              {{ option }}
            </option>
          </select>
          <span v-if="filter.association" class="clear-icon" @click="clearAssociation()"><img
              src="../../assets/cross-icon.png" /></span>
        </div>
        <div class="col-md-3 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
          <select class="" v-model="filter.region" @change="getFilters()">
            <option value="" selected>Select States</option>
            <option v-for="option in stateOptions" :value="option" :key="option.name">
              {{ option.abbreviation }}
            </option>
          </select>
          <span v-if="filter.region" class="clear-icon" @click="clearState()"><img
              src="../../assets/cross-icon.png" /></span>
        </div>
        <div class="col-md-3 px-1 form-group mb-0">
          <date-picker
            :open="open"
            @close="open = false"
            :editable="false"
            :clearable="true"
            @click.native="handleClick"
            v-model="filter.startDate"
            valueType="format"
            format="MM/YYYY"
            placeholder="mm/yyyy"
            @input="checkDateInput"
            @change="getFilters()"
            @clear="handleClear"
            type="month"
          >
            <i slot="icon-calendar" class="cursor-pointer">
              <img src="../../assets/images/calendar-input.png" />
            </i>
          </date-picker>
        </div>
      </div>

      <b-row class="no-gutters" v-if="eventsData.length > 0">
        <div class="col-12 products-column mt-3">
          <b-card class="border-0">
            <b-table :items="eventsData" :fields="fields" hover responsive class="mt-3 b-datatable cursor-pointer"
              @row-clicked="redirectToEvent">
              <template v-slot:cell(eventName)="data">
                <router-link :to="{ name: 'AssociationSingleEventDetail', params: { id: data.item.guid } }"
                  class="stats-link">
                  {{ data.item.eventName }}
                </router-link>
                <!-- <span class="stats-link" @click="handleRowClick(data.item)">{{ data.item.eventName }}</span> -->
              </template>
              <template v-slot:cell(location)="data">
                <div class="event-location" v-if="
                    (data.item.status == 1 &&
                      data.item.address != null &&
                      data.item.address != '' &&
                      data.item.address != 'null') ||
                    (data.item.city != null &&
                      data.item.city != '' &&
                      data.item.city != 'null') ||
                    (data.item.state != null &&
                      data.item.state != '' &&
                      data.item.state != 'null')
                  ">
                  <img  class="mr-2" src="../../assets/images/location.png"  @click.stop="openGoogleMap(data.item)"  />
                  <span class="content"  @click.stop="openGoogleMap(data.item)" >
                    {{ data.item.address }} {{ data.item.city }},
                    {{ data.item.state }}
                  </span>
                </div>
                <div v-else>
                  <!-- Display the medium property -->
                  Online Event
                </div>
              </template>
              <template v-slot:cell(state)="data">
                <div class="event-location" v-if="data.item.state !== null">
                  {{ data.item.state }}
                </div>
                <div v-else>Online Event</div>
              </template>
            </b-table>
            <content-placeholders :rounded="true" v-if="showLoader1">
              <content-placeholders-heading :img="false" />
            </content-placeholders>
          </b-card>
        </div>
      </b-row>

      <div v-if="eventsData.length" v-observe-visibility="handleScrolledToButtom"></div>
    </div>
    <div class="no-products-in-shop" v-if="eventsData.length === 0 && showLoader === 0">
      <img src="../../assets/images/no-product.png" alt />
      <p class="header">No event found.</p>
      <p class="info">
        There are no events to display in the selected category.
      </p>
    </div>
    <div class="products-list-shim mt-3" v-if="eventsData.length == 0 && showLoader == 1">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
      <content-placeholders class="mt-3" :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import MyEventsLiveCard from "../GameTime/MyEventsLiveCard.vue";
let states = require("../../assets/js/state");

export default {
  name: "eventsDataList",
  components: { MyEventsLiveCard },
  data() {
    const today = new Date();
  const currentMonth = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const currentYear = today.getFullYear();
    return {
      stateOptions: states,
      associationOptions: [],
      reverse: false,
      open: false,
      sortKey: "",
      fields: [
        {
          key: "startDateForField",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            const startDate = new Date(item.exactStartDate);
            const endDate = new Date(item.exactEndDate);

            const startDateString = startDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
            const endDateString = endDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });
            const startYearString = startDate.getFullYear();
            const endYearString = endDate.getFullYear();

            if (startYearString === endYearString) {
              return `${startDateString} to ${endDateString}, ${startYearString}`;
            } else {
              return `${startDateString}, ${startYearString} to ${endDateString}, ${endYearString}`;
            }
          },
          sortBy: (item) => {
            // Custom sorting function based on startDateForField
            return new Date(item.startDateForField).getTime(); // Sort by timestamp
          },
        },
        { key: "association", label: "Association", sortable: true },
        { key: "eventName", label: "Name", sortable: true },
        { key: "director_name", label: "Director", sortable: true },
        { key: "eventType", label: "Type", sortable: true },
        {
          key: "location",
          label: "Location",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            return `${item.address || ""}, ${item.city || ""}, ${
              item.state || ""
            }`;
          },
          sortBy: (item) => {
            const location =
              (item.address || "") +
              " " +
              (item.city || "") +
              " " +
              (item.state || "");
            return location.toLowerCase();
          },
        },
        { key: "state", label: "State", sortable: true },
      ],

      filter: {
        name: "",
        startDate: `${currentMonth}/${currentYear}`, // Set default to current month/year
        association: "",
        region: "",
      },
      eventsAll: [],
      messages: [],
      page: 1,
      lastPage: 1,
      eventsData: [],
      showLoader: 0,
      showLoader1: false,
    };
  },

  methods: {
    handleClear() {
    this.open = false; // Close the date picker
  },
    redirectToEvent(item) {
      const eventId = item.guid;
      this.$router.push({ name: 'AssociationSingleEventDetail', params: { id: eventId } });
    },
    handleClick(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    ccustomDateSortCompare(a, b, sortBy, sortDesc) {
      // Check if the sortBy field is a date field
      if (sortBy === "exactStartDate") {
        const dateA = new Date(a[sortBy]);
        const dateB = new Date(b[sortBy]);

        // Handle invalid dates
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          return 0;
        }

        // Compare dates
        return sortDesc
          ? dateB.getTime() - dateA.getTime()
          : dateA.getTime() - dateB.getTime();
      } else {
        // For non-date fields, fallback to default sorting behavior
        return 0;
      }
    },
    showLocation(event) {
      return (
        (event.status === 1 &&
          event.address !== null &&
          event.address !== "" &&
          event.address !== "null") ||
        (event.city !== null && event.city !== "" && event.city !== "null") ||
        (event.state !== null && event.state !== "" && event.state !== "null")
      );
    },
    getLocation(event) {
      const address =
        event.address && event.address !== "null" ? event.address : "";
      const city = event.city && event.city !== "null" ? event.city : "";
      const state = event.state && event.state !== "null" ? event.state : "";

      return `${address} ${city}, ${state}`.trim();
    },
    async getFilters() {
      this.page = 1;
      this.showLoader = 1;
      this.showLoader1 = true;
      this.eventsData = [];
      this.isLoading = true;

      let formattedStartDate = "";
      if (this.filter.startDate) {
        // Split the month/year format
        const startDateParts = this.filter.startDate.split("/");

        // Create a new date object representing the first day of the selected month and year
        formattedStartDate = `${startDateParts[1]}/${startDateParts[0]}`; // Format as MM/DD/YYYY
      }
      try {
        const filteredEvents = await axios.post(
          `${process.env.VUE_APP_API_URL}event/adminCreatedEventListing?page=${this.page}`,
          {
            name: this.filter.name,
            startDate: formattedStartDate,
            age: this.filter.age, // Include age filter
            association: this.filter.association, // Include association filter
            region: this.filter.region.name,
            abbreviation: this.filter.region.abbreviation,
          }
        );

        if (filteredEvents.data.statusCode === 200) {
          const newEventData = filteredEvents.data.data.data;
          if (this.page === 1) {
            this.eventsData = [];
          }
          this.eventsData = this.eventsData.concat(newEventData);
            this.eventsData.sort((a, b) => {
              const dateAStart = new Date(a.exactStartDate);
              const dateBStart = new Date(b.exactStartDate);
              const dateAEnd = new Date(a.exactEndDate);
              const dateBEnd = new Date(b.exactEndDate);
              
              // Compare start dates first
              if (dateAStart.getTime() !== dateBStart.getTime()) {
                  return dateAStart - dateBStart;
              } else {
                  // If start dates are the same, compare end dates
                  return dateAEnd - dateBEnd;
              }
          });

          // this.eventsData = filteredEvents.data.data.data;
          this.lastPage = filteredEvents.data.data.last_page;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        } else {
          this.errorMessage = filteredEvents.data.message;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
        this.showLoader1 = false;
      }
    },
    openGoogleMap(item) {
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    handleRowClick(event) {
      this.$root.$emit("eventsIdForDetails", event);

      // setTimeout(() => {
      //   this.$root.$emit("eventsIdForDetails", event);
      //   this.$router.push({ name: "AssociationSingleEventDetail", params: { id: event.guid } });
      // }, 500);
    },

    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.EventsData();
      }
    },

    async EventsData() {
      this.showLoader = 1;
      this.showLoader1 = true;
      let formattedStartDate = "";
      if (this.filter.startDate) {
        // Split the month/year format
        const startDateParts = this.filter.startDate.split("/");

        // Create a new date object representing the first day of the selected month and year
        formattedStartDate = `${startDateParts[1]}/${startDateParts[0]}`; // Format as MM/DD/YYYY
      }
      try {
        // Include filters in API call if it's selected
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}event/adminCreatedEventListing?page=${this.page}`,
           {
            name: this.filter.name,
            startDate: formattedStartDate,
            age: this.filter.age, // Include age filter
            association: this.filter.association, // Include association filter
            region: this.filter.region.name,
            abbreviation: this.filter.region.abbreviation,
           }
        );

        if (response.data.statusCode === 200) {
          this.eventsData.push(...response.data.data.data);
          this.eventsData.sort((a, b) => {
                const dateA = new Date(a.exactStartDate);
                const dateB = new Date(b.exactStartDate);
                return dateA - dateB;
            });
          this.lastPage = response.data.data.last_page;
          this.showLoader = 0;
          this.showLoader1 = false;
        } else {
          this.errorMessage = response.data.message;
          this.showLoader = 0;
          this.showLoader1 = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
        this.showLoader1 = false;
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
      }
    },
    checkDateInput() {
      if (!this.filter.startDate) {
        this.filter.startDate = "";
        this.getFilters();
      }
    },
    clearName() {
      this.filter.name = "";
      this.getFilters();
    },
    clearAssociation() {
      this.filter.association = "";
      this.getFilters();
    },
    clearState() {
      this.filter.region = "";
      this.getFilters();
    },
    getAssociation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          response.data.data.association.forEach((element) => {
            this.associationOptions.push(element.association_name);
          });
        });
    },
  },
  mounted() {
    this.getAssociation();
    this.EventsData();
    // this.handleEventClick();
  },
};
</script>

<style scoped>
.table thead th {
  min-width: 150px;
}
.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.chat-filter-options {
  background: #cce6fd;
  border-radius: 5px;
  height: 35px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter-counter {
  background: #254c72;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin: 0 5px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.blocker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  bottom: 0;
  right: 0;
  content: " ";
  background: rgba(0, 0, 0, 0.5);
}
.pinned-options {
  top: 9%;
  left: inherit;
  margin-right: -13%;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
</style>
