<template>
  <div class="media-images" :id="photo.id">
    <div class="shop-products-img-loader" v-if="imgLoader == 1">
      <div class="lds-dual-ring-media"></div>
    </div>
    <video
      @loadeddata="onImageLoad"
      v-if="photo.type == 'video/mp4'"
      style="
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        cursor: pointer;
      "
        @click="OpenVideoPreview"    >
      <source :src="photo.url" type="video/mp4" />
    </video>
    <!-- <audio
      v-if="photo.type == 'audio/mpeg' || photo.type == 'audio/mp3'"
      @loadedmetadata="onImageLoad"
      style="
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      "
      controls
      @click="OpenAudioPreview"
    >
      <source :src="photo.url" type="audio/mp3" />
    </audio> -->

    <img
      @load="onImageLoad"
      :src="mp3_icon"
      alt=""
      @click="OpenAudioPreview"
      v-if="photo.type == 'audio/mpeg' || photo.type == 'audio/mp3'"
    />

    <img
      @load="onImageLoad"
      :src="photo.url"
      alt=""
      @click="OpenPhotosPreview"

      v-if="
        photo.type == 'image/jpeg' ||
        photo.type == 'image/png' ||
        photo.type == 'image/jpg'
      "
    />

    <div class="photos-modal" v-if="openVideos">
      <button
        class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
        @click="CloseVideoPreview"
      >
        <i class="fa fa-times"></i>
      </button>
      <div @click="CloseVideoPreview" v-if="photo.type == 'video/mp4'">
        <video
          controls
          autoplay
          style="width: 80%; margin: 0 auto; display: flex; height: 100vh"
        >
          <source :src="photo.url" type="video/mp4" />
        </video>
      </div>
    </div>
    <div class="photos-modal" v-if="openAudios">
      <button
        class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
        @click="CloseAudioPreview"
      >
        <i class="fa fa-times"></i>
      </button>
      <div
        @click="CloseAudioPreview"
        v-if="
          photo.type == 'audio/mpeg' ||
          photo.type == 'audio/ogg' ||
          photo.type == 'audio/mp3'
        "
      >
        <audio
          controls
          autoplay
          style="width: 80%; margin: 0 auto; display: flex; height: 90vh"
        >
          <source :src="photo.url" type="audio/mp3" class="d-none" />
        </audio>
      </div>
    </div>
    <div class="photos-modal" v-if="openPhotos">
      <button
        class="close-icon btn btn-outline-danger btn-sm lb-modal-close"
        @click="ClosePhotosPreview"
      >
        <i class="fa fa-times"></i>
      </button>
      <div class="photos-full-preview" @click="ClosePhotosPreview">
        <img :src="photo.url" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhotosPreview",
  data() {
    return {
      openPhotos: false,
      openAudios: false,
      openVideos: false,
      imgLoader: 1,
      mp3_icon: require("../../../../../assets/images/mp3.jpg"),
    };
  },
  props: {
    photo: {
      type: Object,
    },
  },
  methods: {
    openInNewWindow(url) {
      window.open(url, '_blank'); // Open the media in a new tab
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    OpenVideoPreview() {
      this.openVideos = true;
    },
    CloseVideoPreview() {
      this.openVideos = false;
    },
    OpenAudioPreview() {
      this.openAudios = true;
    },
    CloseAudioPreview() {
      this.openAudios = false;
    },
    OpenPhotosPreview() {
      this.openPhotos = true;
    },
    ClosePhotosPreview() {
      this.openPhotos = false;
    },
  },
};
</script>

