<template>
  <div>
    <GameListModal />
    <div class="single-box-score-wrap">
      <div class="single-box-score-left-side">
        <div class="single-score-table single-box-score-wrapper">
          <div class="sec-top">
            <div class="left-part" v-if="showShimmer == 0">
              <div class="cursor-pointer" @click="openGameModal">
                <div class="switch-game">
                  <span class="content">Switch Games</span>
                  <h4 class="text-capitalize">{{ singleGame.name }}</h4>
                </div>
              </div>
              <p class="mt-2">
                {{ singleGame.park_name }}
                {{ singleGame.field ? "- " + singleGame.field.field_name : "" }}
              </p>
              <div class="event-date">
                <img src="../../assets/images/calendar-event.png" />
                <div class="content">{{ singleGame.exactDateFormat }}</div>
                <div v-if="singleGame.actual_start_time" class="content">
                  - Actual Start Time:
                  {{ singleGame.actual_start_time | formatTimeToAM }}
                </div>
                <div v-if="singleGame.time_limit" class="content">
                  - Time Limit: {{ singleGame.time_limit }} minutes
                </div>
              </div>
              <div
                class="location-content"
                v-if="
                  (singleGame.event.status == 1 &&
                    singleGame.event.address != null &&
                    singleGame.event.address != '' &&
                    singleGame.event.address != 'null') ||
                    (singleGame.event.city != null &&
                      singleGame.event.city != '' &&
                      singleGame.event.address != 'null') ||
                    (singleGame.event.state != null &&
                      singleGame.event.state != '' &&
                      singleGame.event.state != 'null')
                "
              >
                <img
                  src="../../assets/images/location.png"
                  @click="openGoogleMap(singleGame)"
                />
                <span class="content" @click="openGoogleMap(singleGame)"
                  >{{ singleGame.event.address }} {{ singleGame.event.city }},
                  {{ singleGame.event.state }}</span
                >
              </div>
              <div class="location-content" v-if="singleGame.event.status == 2">
                <img src="../../assets/images/link.png" />
                <a class="content" :href="singleGame.event.Url" target="_blank">
                  {{ singleGame.event.Url }}
                </a>
              </div>
            </div>
            <div class="right-part" v-if="showShimmer == 0">
                <router-link :to="{ name: 'SingleEventDetail', params: { id: singleGame.event.id } }">
                <h4 class="text-capitalize text-md-right cursor-pointer text-primary">
                  {{ singleGame.event.eventName }}
                </h4>
              </router-link>
              <p class="mt-2 text-md-right text-capitalize">
                {{ visitTeamName }} <span class="text-lowercase" v-if="visitTeamName && homeTeamName" >vs</span>
                {{ homeTeamName }}
              </p>
              <div
                class="w-100 d-flex justify-content-md-end"
                v-if="currentIsAdmin == '1'"
              >
                <button
                  class="btn scoresheet-btn"
                  @click="openFileUploadModal(singleGame)"
                  v-if="singleGame.score_sheet_url == null"
                >
                  Upload scoresheet
                </button>
                <div class="dropdown" v-else>
                  <button
                    class="btn dropdown-toggle scoresheet-btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Scoresheet</span>
                  </button>
                  <div
                    class="dropdown-menu sub-setting-options"
                    aria-labelledby="dropdownMenuButton"
                    v-if="singleGame.score_sheet_url != null"
                  >
                    <ul class="mb-0">
                      <li
                        class="del-game"
                        @click="viewScoresheet(singleGame.score_sheet_url)"
                      >
                        View Scoresheet
                      </li>

                      <li class="del-game" @click="deleteScoresheet()">
                        Delete Scoresheet
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                class="opt position-relative float-md-right cursor-pointer"
                v-if="currentIsAdmin == '1'"
              >
                <img
                  src="../../assets/images/ellipsis-h.png"
                  alt=""
                  @click="openGameOptions"
                  v-click-outside="onClickOutside"
                />
                <div class="sub-setting-options" v-if="gameOptions">
                  <ul class="mb-0">
                    <li
                      class="del-game"
                      data-toggle="modal"
                      data-target="#exampleModalEdit"
                      @click="checkUpdateBoxScore"
                    >
                      Update
                    </li>

                    <li class="del-game" @click="deleteGame()">Delete</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <content-placeholders
            :rounded="true"
            v-if="showShimmer == 1"
            class="mt-3"
          >
            <content-placeholders-text :lines="6" />
          </content-placeholders>
          <div class="inning-sec" v-if="showShimmer == 0">
            <div class="teams-info-sec">
              <table class="table">
                <thead>
                  <tr>
                    <th class="invisible">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-left">
                    <td
                      scope="col"
                      class="text-capitalize ellipsis-cell"
                      v-if="this.scores.length != 0"
                    >
                      {{ visitTeamName }}
                    </td>
                    <td v-else>-----</td>
                  </tr>
                  <tr class="text-left">
                    <td
                      scope="col"
                      class="text-capitalize ellipsis-cell"
                      v-if="this.scores.length != 0"
                    >
                      {{ homeTeamName }}
                    </td>
                    <td v-else>-----</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="inning-info-sec">
              <table
                class="table table-float-right"
                v-if="this.scores.length != 0 && totalInnings > 1"
              >
                <thead>
                  <tr>
                    <th
                      class="text-center"
                      scope="col"
                      v-for="n in totalInnings - 1"
                      :key="n"
                    >
                      {{ n }}
                    </th>
                    <th class="text-center" scope="col">{{ totalInnings }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      class="text-center"
                      v-for="inning in previousInningVisitScore"
                      :key="inning.id + 1000"
                    >
                      <div
                        class="cursor-pointer"
                        @click="
                          callPeviousScoreUpdateModal(
                            inning.inning_no,
                            inning.game_score_id,
                            inning.game_id
                          )
                        "
                      >
                        {{ inning.score == null ? "0" : inning.score }}
                      </div>
                    </td>
                    <td class="text-center">
                      <div :class="currentTeamType == 1 ? 'span' : ''">
                        <div v-if="currentTeamType === 1">
                          {{ currentInningVisitScore }}
                        </div>
                        <div
                          v-else
                          class="cursor-pointer"
                          @click="
                            callPeviousScoreUpdateModal(
                              currentInningVisitScoreArray.inning_no,
                              currentInningVisitScoreArray.game_score_id,
                              currentInningVisitScoreArray.game_id
                            )
                          "
                        >
                          {{ currentInningVisitScore }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="text-center"
                      v-for="inning in previousInningHomeScore"
                      :key="inning.id + 2000"
                    >
                      <div
                        class="cursor-pointer"
                        @click="
                          callPeviousScoreUpdateModal(
                            inning.inning_no,
                            inning.game_score_id,
                            inning.game_id
                          )
                        "
                      >
                        {{ inning.score == null ? "0" : inning.score }}
                      </div>
                    </td>
                    <td class="text-center">
                      <div :class="currentTeamType == 2 ? 'span' : ''">
                        <div>{{ currentInningHomeScore }}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table table-float-right"
                v-if="this.scores.length != 0 && totalInnings == 1"
              >
                <thead>
                  <tr>
                    <th scope="col" class="text-center">1</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <div :class="currentTeamType == 1 ? 'span' : ''">
                        <div v-if="currentTeamType === 1">
                          {{ currentInningVisitScore }}
                        </div>
                        <div
                          v-else
                          class="cursor-pointer"
                          @click="
                            callPeviousScoreUpdateModal(
                              currentInningVisitScoreArray.inning_no,
                              currentInningVisitScoreArray.game_score_id,
                              currentInningVisitScoreArray.game_id
                            )
                          "
                        >
                          {{ currentInningVisitScore }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <div :class="currentTeamType == 2 ? 'span' : ''">
                        <div>{{ currentInningHomeScore }}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="inning-score">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">Runs</th>
                    <th scope="col" class="text-center">HR</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="col" class="text-center">
                      {{ totalVisitScore }}
                    </td>
                    <td scope="col" class="text-center">{{ totalVisitHR }}</td>
                  </tr>
                  <tr>
                    <td scope="col" class="text-center">
                      {{ totalHomeScore }}
                    </td>
                    <td scope="col" class="text-center">{{ totalHomeHR }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="teams-sec my-0">
            <TeamSelect
              :homeTeamName="homeTeamName"
              :visitTeamName="visitTeamName"
              :selectedRoomTeam="selectedRoomTeam"
            />
            <div class="team-actions-line-up-wrapper">
              <TeamActions />
              <div
                class="lineup"
                @click="callModalFields()"
                v-if="currentIsAdmin == '1'"
              >
                <img src="../../assets/images/edit.png" alt="" />
                <small>Field Configuration</small>
              </div>
              <TeamLineUp
                v-if="currentIsAdmin == '1'"
                :singleGame="singleGame"
                :selectedRoomTeam="selectedRoomTeam"
              />
            </div>
          </div>
          <TeamsHistory
            :currentIsAdmin="currentIsAdmin"
            :singleGame="singleGame"
            :selectedRoomTeam="selectedRoomTeam"
          />
        </div>
      </div>

      <div class="single-box-score-right-side">
        <div class="order-history-right-side mt-3 mt-md-0">
          <RightSide
            :currentIsAdmin="currentIsAdmin"
            :gameFetchedright="singleGame"
            :selectedRoomTeam="selectedRoomTeam"
            @passData="GetData($event)"
            @passHrData="GetHrData($event)"
            :totalInnings="totalInnings"
            :currentBattingTeam="currentBattingTeam"
            :currentTeamType="currentTeamType"
            :showShimmer="showShimmer"
            :homeTeamName="homeTeamName"
            :visitTeamName="visitTeamName"
            :currentInningHomeHR="currentInningHomeHRArray"
            :currentInningHomeScore="currentInningHomeScoreArray"
            :currentInningVisitHR="currentInningVisitHRArray"
            :currentInningVisitScore="currentInningVisitScoreArray"
            :scores="scores"
            :configDataFromChild="configDataFromChild"
            :fetchConfig="fetchConfig"
            :gameLineUps="gameLineUps"
          />
        </div>
      </div>

      <!-- Update Score Modal -->
      <div
        data-target="#AddScoresModal"
        data-toggle="modal"
        ref="openAddScores"
        @click="openUpdateScoreModal()"
      ></div>
      <div
        class="modal fade"
        id="AddScoresModal"
        tabindex="-1"
        aria-labelledby="AddScoresModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="AddScoresModalLabel">
                Update Scores
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeAddScoresModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="game-name">
                <span style="font-size: 15px">
                  {{ updateScoreTeamNameFlag }}
                  <b
                    >({{
                      updateScoreTeamTypeFlag == 1
                        ? "Visiting Team"
                        : "Home Team"
                    }})</b
                  >
                </span>
              </div>
              <div class="game-name">
                <span style="font-size: 15px">
                  Inning<b> ({{ updateScoreInningNoFlag }})</b>
                </span>
              </div>
              <br />

              <form @keyup.enter="savePreviousScore(0)" @submit.prevent>
                <div class="form-group" id="fieldset-1" label-for="score">
                  <input
                    id="updateScoreRunFlag"
                    autocomplete="off"
                    type="text" 
                    v-model="updateScoreRunFlag"
                    maxlength="2"
                    required
                    @keyup="errorNull()"
                    @keypress="validateNumber"
                    @input="handleInput"
                  />

                  <span class="placeholder">Enter Runs</span>
                </div>
                <div class="form-group" id="fieldset-1" label-for="hr">
                  <input
                    id="updateScoreHRFlag"
                    autocomplete="off"
                    type="text" 
                    v-model="updateScoreHRFlag"
                    maxlength="2"
                    required
                    @keyup="errorNull()"
                    @keypress="validateNumber"
                    @input="handleInput"
                  />

                  <span class="placeholder">Enter HR</span>
                </div>
              </form>
            </div>
            <div class="modal-footer sign-in">
              <b-button
                class="auth-btn font-weight-normal text-white"
                style="width: 100px"
                v-if="isDisabledUpdateScore == true"
                :disabled="true"
              >
                <span>Save </span>
              </b-button>
              <b-button
                v-else
                class="auth-btn font-weight-normal text-white"
                style="width: 100px"
                :disabled="isLoadingArrayScore[0]"
                @click="savePreviousScore(0)"
              >
                <div v-if="isLoadingArrayScore[0]" class="lds-dual-ring"></div>
                <span v-else> Save </span>
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Update Score Modal -->
    </div>
    <UploadSheetModal />
    <AddConfiguration
      :selectedRoomTeam="selectedRoomTeam"
      :singleGame="singleGame"
      @config-updated="updateConfigData"
    />
    <EditGame :singleGame="singleGame" />
  </div>
</template>

<script>
import axios from "axios";
import EditGame from "../../components/Teams/Games/EditGame.vue";
import RightSide from "./RightSide.vue";
import TeamsHistory from "./SubLeft/TeamsHistory.vue";
import TeamSelect from "./SubLeft/TeamSelect.vue";
import TeamActions from "./SubLeft/TeamActions.vue";
import TeamLineUp from "./SubLeft/TeamLineUp.vue";
import AddConfiguration from "./SubLeft/AddConfiguration.vue";
import UploadSheetModal from "../../components/Teams/Games/UploadSheetModal.vue";
import GameListModal from "./SubLeft/GameListModal.vue";

export default {
  name: "LeftSide",
  data() {
    return {
      gameOptions: false,
      currentRuns: 0,
      hr: 0,
      gameScores: [],
      toggleButton: false,
      previousInningVisitScore: [],
      previousInningVisitHR: [],
      previousInningHomeScore: [],
      previousInningHomeHR: [],
      currentInningHomeHRArray: {},
      currentInningHomeScoreArray: {},
      currentInningVisitHRArray: {},
      currentInningVisitScoreArray: {},
      currentInningVisitScore: 0,
      currentInningVisitHR: 0,
      currentInningHomeScore: 0,
      currentInningHomeHR: 0,
      homeTeamName: "",
      visitTeamName: "",
      totalHomeHR: 0,
      totalVisitHR: 0,
      totalHomeScore: 0,
      totalVisitScore: 0,
      totalInnings: 1,
      currentBattingTeam: "",
      currentTeamType: 0,
      updateScoreRunFlag: 0,
      updateScoreHRFlag: 0,
      updateScoreInningNoFlag: 0,
      updateScoreGameIdFlag: 0,
      updateScoreGameScoreIdFlag: 0,
      updateScoreTeamNameFlag: "",
      updateScoreTeamTypeFlag: 0,
      isLoadingArrayScore: [],
      isDisabledUpdateScore: true,
      gameList: {},
      configDataFromChild: [],
      fetchConfig: [],
      gameLineUps: [],
    };
  },
  props: {
    currentIsAdmin: {
      type: String,
    },
    singleGame: {
      type: Object,
    },
    selectedRoomTeam: {
      type: Object,
    },
    showShimmer: {
      type: Number,
    },
    scores: {
      type: Array,
    },
  },

  components: {
    RightSide,
    GameListModal,
    EditGame,
    TeamsHistory,
    TeamSelect,
    TeamActions,
    TeamLineUp,
    AddConfiguration,
    UploadSheetModal,
  },

  watch: {
    singleGame(newValue) {
      this.scoringCalculation(newValue.game_scores);
    },
    "configDataFromChild.configField"(newConfigField) {
      console.log("configField changed:", newConfigField);
    },
    "fetchConfigDataFromChild.configField"(newConfigField) {
      console.log("configField fetched:", newConfigField);
    },
  },

  methods: {
    getEvent(singleGame) {
  this.$router.push({
        name: "SingleEventDetail",
        params: { id: singleGame.event.id },
      });
  },
    updateConfigData(config) {
      this.configDataFromChild = config;
    },
    callModalFields: function() {
      this.$root.$emit("AddConfiguration", this.singleGame);
    },
    openGameModal: function() {
      this.$root.$emit("openGamesDataModal", this.singleGame);
    },
    validateNumber(event) {
      const char = String.fromCharCode(event.keyCode);
      if (!/^\d+$/.test(char)) {
        event.preventDefault();
      }
    },
    handleInput(event) {
      this[event.target.id] = event.target.value.replace(/\D/g, '');
    },
    openUpdateScoreModal(){
      this.errorNull();
    },
    errorNull: function () {
      if (this.updateScoreRunFlag !== "" && this.updateScoreHRFlag !== "") {
          this.isDisabledUpdateScore = false;
      } else {
          this.isDisabledUpdateScore = true;
      }
    },
    // isNumber: function() {
    //   if (
    //     /^[0-9]\d*$/.test(this.updateScoreHRFlag) &&
    //     /^[0-9]\d*$/.test(this.updateScoreRunFlag)
    //   ) {
    //     this.isDisabledUpdateScore = false;
    //   } else {
    //     this.isDisabledUpdateScore = true;
    //   }
    // },
    openGameOptions() {
      this.gameOptions = !this.gameOptions;
    },
    onClickOutside() {
      this.gameOptions = false;
    },

    GetData(data) {
      if (this.currentTeamType == 1) {
        this.totalVisitScore =
          this.totalVisitScore - this.currentInningVisitScore;
        this.currentInningVisitScore = data;
        this.totalVisitScore =
          this.totalVisitScore + this.currentInningVisitScore;
      } else {
        this.totalHomeScore = this.totalHomeScore - this.currentInningHomeScore;
        this.currentInningHomeScore = data;
        this.totalHomeScore = this.totalHomeScore + this.currentInningHomeScore;
      }
    },
    GetHrData(data) {
      if (this.currentTeamType == 1) {
        this.totalVisitHR = this.totalVisitHR - this.currentInningVisitHR;
        this.currentInningVisitHR = data;
        this.totalVisitHR = this.totalVisitHR + this.currentInningVisitHR;
      } else {
        this.totalHomeHR = this.totalHomeHR - this.currentInningHomeHR;
        this.currentInningHomeHR = data;
        this.totalHomeHR = this.totalHomeHR + this.currentInningHomeHR;
      }
    },

    openFileInput() {
      this.$refs.scoresheetInput.click();
    },

    async viewScoresheet(url) {
      try {
        if (url) {
          const fullUrl = `${process.env.VUE_APP_API_URL_STORAGE_PDF}${url}`;
          window.open(fullUrl, "_blank");
        } else {
          console.error("PDF URL not available in the response");
        }
      } catch (error) {
        console.error("Error fetching PDF URL:", error);
      }
    },
    async deleteScoresheet() {
      try {
        let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,

        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });
        await axios.get(
          `${process.env.VUE_APP_API_URL}game/deleteGameScoreSheet/${this.singleGame.id}`
        );
        loader.hide();
        this.$root.$emit("showSingleBoxScore");
      } catch (error) {
        // Handle errors
        console.error("Error deleting scoresheet:", error);
      }
    },

    checkUpdateBoxScore() {
      this.$root.$emit("checkUpdateGame", this.singleGame);
    },
    openFileUploadModal(singleGame) {
      this.$root.$emit("opneUploadExcelModal", singleGame);
    },

    openGoogleMapforGamePark(item) {
      const strlatLng = item["park_lat"] + "," + item["park_lng"];
      const urlSuffix = strlatLng ? strlatLng : item["park_address"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    async deleteGame() {
      this.$confirm(
        "About to remove this game. Continue ?",
        "Remove Game."
      ).then(() => {
        var formData = new FormData();
        formData.append("id", this.singleGame.id);

        axios
          .post(process.env.VUE_APP_API_URL + "game/delete", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.$router.push("/teams");
          });
      });
    },

    callPeviousScoreUpdateModal(inning_no, game_score_id, game_id) {
      this.isDisabledUpdateScore = true;
      this.updateScoreGameIdFlag = game_id;
      this.updateScoreInningNoFlag = inning_no;
      this.updateScoreGameScoreIdFlag = game_score_id;

      this.gameScores.forEach((gameS) => {
        if (gameS.id == game_score_id) {
          this.updateScoreTeamNameFlag = gameS.team_name;
          this.updateScoreTeamTypeFlag = gameS.team_type;
          gameS.game_innings.forEach((inning) => {
            // Previous Inning Score
            if (inning.inning_no == inning_no) {
              if (inning.inning_type == 1) {
                this.updateScoreHRFlag = inning.score;
              }
              if (inning.inning_type == 2) {
                this.updateScoreRunFlag = inning.score;
              }
            }
          });
        }
      });

      if (this.singleGame.status != 2 && this.currentIsAdmin == "1") {
        var elem = this.$refs.openAddScores;
        if (elem) {
          elem.click();
        }
      }
    },

    async savePreviousScore(index) {
      if (this.isDisabledUpdateScore != true) {
        this.$set(this.isLoadingArrayScore, index, true);
        var formData = new FormData();
        formData.append("game_id", this.updateScoreGameIdFlag);
        formData.append("inning_no", this.updateScoreInningNoFlag);
        formData.append("game_score_id", this.updateScoreGameScoreIdFlag);
        formData.append("run_score", this.updateScoreRunFlag);
        formData.append("hr_score", this.updateScoreHRFlag);
        await axios
          .post(
            process.env.VUE_APP_API_URL + "game/updatePreviousScore",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.statusCode == 404) {
              alert(response.data.message);
            }
            this.$set(this.isLoadingArrayScore, 0, false);
            var elem = this.$refs.closeAddScoresModal;
            if (elem) {
              elem.click();
            }
            this.$root.$emit("showSingleBoxScore");
          });
      }
    },

    async scoringCalculation(gameScores) {
      this.gameScores = [];
      this.totalInnings = 1;
      this.previousInningHomeScore = [];
      this.previousInningHomeHR = [];
      this.currentInningHomeHRArray = [];
      this.currentInningHomeScoreArray = [];
      this.totalHomeScore = 0;
      this.totalHomeHR = 0;
      this.currentInningHomeScore = 0;
      this.currentInningHomeHR = 0;
      this.homeScoreId = 0;
      this.homeHRId = 0;

      this.previousInningVisitScore = [];
      this.previousInningVisitHR = [];
      this.currentInningVisitHRArray = [];
      this.currentInningVisitScoreArray = [];
      this.totalVisitScore = 0;
      this.totalVisitHR = 0;
      this.currentInningVisitScore = 0;
      this.currentInningVisitHR = 0;
      this.visitScoreId = 0;
      this.visitHRId = 0;
      this.gameScores = gameScores;

      if (this.gameScores.length > 0) {
        const inning = this.gameScores[0].game_innings.length;
        this.totalInnings = inning / 2;

        // Visiting
        this.gameScores.forEach((gameS) => {
          if (gameS.team_type == 1) {
            this.visitTeamName = "";
            this.visitTeamName = gameS.team_name;
            this.currentBattingTeam =
              gameS.batting_flag == 1 ? gameS.team_name : "";
            this.currentTeamType =
              gameS.batting_flag == 1 ? gameS.team_type : 0;
            gameS.game_innings.forEach((inning) => {
              // Previous Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 1) {
                this.totalVisitScore =
                  inning.score != null
                    ? parseInt(this.totalVisitScore) + parseInt(inning.score)
                    : this.totalVisitScore;
                this.previousInningVisitScore.push(inning);
              }
              // Previous Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 1) {
                this.totalVisitHR =
                  inning.score != null
                    ? parseInt(this.totalVisitHR) + parseInt(inning.score)
                    : this.totalVisitHR;
                this.previousInningVisitHR.push(inning);
              }
              // Current Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 2) {
                this.visitScoreId = inning.id;
                this.currentInningVisitScore = inning.score;
                this.totalVisitScore =
                  inning.score != null
                    ? parseInt(this.totalVisitScore) + parseInt(inning.score)
                    : this.totalVisitScore;
                this.currentInningVisitScoreArray = inning;
              }
              // Current Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 2) {
                this.visitHRId = inning.id;
                this.currentInningVisitHR = inning.score;
                this.totalVisitHR =
                  inning.score != null
                    ? parseInt(this.totalVisitHR) + parseInt(inning.score)
                    : this.totalVisitHR;
                this.currentInningVisitHRArray = inning;
              }
            });
          }
        });

        // Home
        this.gameScores.forEach((gameS) => {
          if (gameS.team_type == 2) {
            this.homeTeamName = "";
            this.homeTeamName = gameS.team_name;
            this.currentBattingTeam =
              gameS.batting_flag == 1
                ? gameS.team_name
                : this.currentBattingTeam;
            this.currentTeamType =
              gameS.batting_flag == 1 ? gameS.team_type : this.currentTeamType;
            gameS.game_innings.forEach((inning) => {
              // Previous Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 1) {
                this.totalHomeScore =
                  inning.score != null
                    ? parseInt(this.totalHomeScore) + parseInt(inning.score)
                    : this.totalHomeScore;
                this.previousInningHomeScore.push(inning);
              }
              // Previous Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 1) {
                this.totalHomeHR =
                  inning.score != null
                    ? parseInt(this.totalHomeHR) + parseInt(inning.score)
                    : this.totalHomeHR;
                this.previousInningHomeHR.push(inning);
              }
              //  Current Inning Score
              if (inning.inning_type == 2 && inning.inning_status == 2) {
                this.homeScoreId = inning.id;
                this.currentInningHomeScore = inning.score;
                this.totalHomeScore =
                  inning.score != null
                    ? parseInt(this.totalHomeScore) + parseInt(inning.score)
                    : this.totalHomeScore;
                this.currentInningHomeScoreArray = inning;
              }
              // Current Inning HR
              else if (inning.inning_type == 1 && inning.inning_status == 2) {
                this.homeHRId = inning.id;
                this.currentInningHomeHR = inning.score;
                this.totalHomeHR =
                  inning.score != null
                    ? parseInt(this.totalHomeHR) + parseInt(inning.score)
                    : this.totalHomeHR;
                this.currentInningHomeHRArray = inning;
              }
            });
          }
        });
      }

      this.$root.$emit(
        "updateScoreHr",
        this.currentTeamType == 1
          ? this.currentInningVisitHR
          : this.currentInningHomeHR,
        this.currentTeamType == 1
          ? this.currentInningVisitScore
          : this.currentInningHomeScore
      );
    },
    fetchFieldUponEmit(fieldConfigId) {
      if (
        this.selectedRoomTeam.teamType == "sports" ||
        !this.selectedRoomTeam.sportType
      ) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
              `game/savesFieldConfigPositions?configField_id=${fieldConfigId}`
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.fetchConfig = response.data.data.GamePositionConfigType;
              this.fetchTeamPosition();
            } else {
              this.errorMessage = response.data.message;
            }
          });
      } else {
        this.$alert("Sport type is not selected yet.");
      }
    },
    fetchField() {
      if (
        this.selectedRoomTeam.teamType == "sports" ||
        !this.selectedRoomTeam.sportType
      ) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
              `game/savesFieldConfigPositions?configField_id=${this.singleGame.field_configuration_id}`
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.fetchConfig = response.data.data.GamePositionConfigType;
              this.fetchTeamPosition();
            } else {
              this.errorMessage = response.data.message;
            }
          });
      } else {
        this.$alert("Sport type is not selected yet.");
      }
    },
    fetchTeamPosition() {
      var formData = new FormData();
      formData.append("gameId", this.singleGame.id);
      axios
        .post(process.env.VUE_APP_API_URL + "game/getGameLineups", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameLineUps = response.data.data.gameLineUps;
          this.$root.$emit("RefreshPlotConfigData", this.gameLineUps);
        });
    },
    fetchTeamPositionUponEmit(scheduleId) {
      var formData = new FormData();
      formData.append("gameId", scheduleId);
      axios
        .post(process.env.VUE_APP_API_URL + "game/getGameLineups", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameLineUps = response.data.data.gameLineUps;
          this.$root.$emit("RefreshPlotConfigData", this.gameLineUps);
        });
    },
  },
  mounted() {
    this.scoringCalculation(this.singleGame.game_scores);
    this.$root.$on("checkUpdateEdit", () => {
      var elem = this.$refs.getUpdate;
      elem.click();
    });
    this.fetchField();
    this.$root.$on("HitfetchTeamPosition", () => {
      this.fetchTeamPosition();
    });
    this.$root.$on("HitfetchTeamPositionUponEmit", (data) => {
      this.fetchTeamPositionUponEmit(data.scheduleId);
      this.fetchFieldUponEmit(data.fieldConfigId);
    });

    // this.$root.$on("passScoresToSingleBoxScore", (gameScores) => {
    //   this.scoringCalculation(gameScores);
    // });
  },
};
</script>

<style scoped>
.table thead th {
  border-bottom: none !important;
}

.table-float-right {
  width: 50% !important;
  margin-left: auto !important;
}
.scoresheet-btn {
  font-weight: 400;
  background: #e2f5fe !important;
  border-radius: 5px;
  padding: 6px 8px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  outline: none;
  color: black;
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

@media screen and (max-width: 768px) {
  .ellipsis-cell {
    padding-bottom: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.2;
    max-height: calc(1.2 * 2); /* Adjust for 2 lines */
    text-overflow: ellipsis;
    white-space: normal;
  }
}

@media screen and (min-width: 768px) {
  #AddScoresModal .modal-content {
    max-width: 300px;
  }
}
@media screen and (max-width: 480px) {
  .teams-sec {
    flex-direction: column !important;
    flex-wrap: wrap !important;
  }
}
</style>
