<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#manageParks"
      ref="openModalManageParks"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="manageParks">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title m-0">Manage Parks</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeManageParks"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="teammates-lineup-wrapper">
              <div class="available-teammates">
                <h6>Available Parks</h6>
                <div class="teammates-info">
                  <draggable
                    class="list-group"
                    :list="availableParks"
                    group="people"
                    handle=".handle"
                    @change="handleAvailableParks"
                    v-if="showLoaderAvailableParks == 0"
                  >
                    <div
                      class="list-group-item cursor-none"
                      v-for="(element, index) in availableParks"
                      :key="index"
                    >
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="d-flex flex-column">
                        <p class="name">{{ element?.park_name }}</p>
                        <small>{{ element?.field_count }} Fields</small>
                        </div>
                      </div>
                      <div class="handle cursor-pointer">
                        <img
                          src="../../../assets/images/line-ellipsis.png"
                          alt=""
                          title="Click an icon to drag and drop the option."
                        />
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="no-line-up"
                    v-if="
                      showLoaderAvailableParks == 0 &&
                        availableParks.length == 0
                    "
                  >
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No available parks found.</p>
                  </div>
                  <div
                    class="lds-dual-ring-media"
                    v-if="showLoaderAvailableParks == 1"
                  ></div>
                </div>
              </div>
              <div class="team-lineup">
                <h6>Selected Parks</h6>
                <div class="teammates-info position-relative">
                  <draggable
                    class="list-group"
                    :list="selectedParks"
                    group="people"
                    handle=".handle"
                    @change="handleSelectedParks"
                    v-if="showLoaderSelectedParks == 0"
                  >
                    <div
                      class="list-group-item cursor-none"
                      v-for="(element, index) in selectedParks"
                      :key="element?.position_index"
                    >
                      <div class="shirt-no-wrapper">
                        <small>{{ index + 1 }}.</small>
                        <div class="d-flex flex-column">
                        <p class="name">{{ element?.park_name }}</p>
                        <small>{{ element?.field_count }} Fields</small>
                      </div>
                      </div>
                      <div class="handle cursor-pointer">
                        <img
                          src="../../../assets/images/line-ellipsis.png"
                          alt=""
                          title="Click an icon to drag and drop the option."
                        />
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="no-line-up"
                    v-if="
                      showLoaderSelectedParks == 0 && selectedParks.length == 0
                    "
                  >
                    <div class="img">
                      <img src="../../../assets/images/teammates.png" alt="" />
                    </div>
                    <p class="text">No selected parks found.</p>
                  </div>
                  <div
                    class="lds-dual-ring-media"
                    v-if="showLoaderSelectedParks == 1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3 justify-content-end">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-25"
              block
              @click="manageParks(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import draggable from "vuedraggable";
export default {
  name: "ManageParks",
  components: {
    draggable,
  },
  props: {
    eventId: {
      type: Number,
    },
    association_id: {
      type: Number,
    },
  },
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      selectedParks: [],
      showLoaderSelectedParks: 1,
      availableParks: [],
      showLoaderAvailableParks: 1,
    };
  },
  watch: {
  selectedParks: function(newValue) {
    
    if (newValue.length > 0 || this.availableParks.length > 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  },
  availableParks: function(newValue) {
    if (newValue.length > 0 || this.selectedParks.length > 0) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  },
},


  methods: {
    closeModal(){
      var elem = this.$refs.closeManageParks;
        if (elem) {
          elem.click();
        }
    },
    async manageParks(index) {
      this.$set(this.isLoadingArray, index, true);
      try {
        const url = `${process.env.VUE_APP_API_URL}associationEvent/createEventPark`;
        const response = await axios.post(url, {
          event_id: this.eventId,
          selectedParks: this.selectedParks,
          association_id: this.association_id,
        });

        // Handle the response based on statusCode
        if (response.data.statusCode === 200) {
          this.$root.$emit("selectedParkEmit");
          this.closeModal();
        } else if (response.data.statusCode === 404) {
          this.closeModal();
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.$set(this.isLoadingArray, index, false);
        }
      } catch (error) {
        console.error("Error saving selected parks:", error);
      } finally {
        this.$set(this.isLoadingArray, index, false);
      }
    },
    handleAvailableParks(evt) {
      if (evt.added) {
        this.selectedParks.push(evt.added.element);
      }
    },
    handleSelectedParks(evt) {
      if (evt.removed) {
        const index = this.selectedParks.indexOf(evt.removed.element);
        if (index > -1) {
          this.selectedParks.splice(index, 1);
        }
      }
    },

    async getPlayingFacilityForAssociation() {
      try {
        this.showLoaderSelectedParks = 1;
        this.showLoaderSelectedParks = 1;

        // Prepare the parameters
        const params = {
            event_id: this.eventId,
            association_id: this.association_id,
        };

        const response = await axios.get(
            `${process.env.VUE_APP_API_URL}associationEvent/getEventPlayingFacility?event_id=${this.eventId}&association_id=${this.association_id}`,
            { params } // Send parameters as query params
        );

        if (response.data.statusCode === 200) {
          this.selectedParks = Object.values(response.data.data.parkDetails);
          this.showLoaderSelectedParks = 0;
        } 
        // else if (response.data.statusCode === 404) {
        //   this.closeModal();
        //   this.$alert(response.data.message, 'Error');
        //   this.$router.push({ name: "Teams" });
        // }
         else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoaderSelectedParks = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoaderSelectedParks = 0;
      }
    },
    async getPlayingFacilities() {
      try {
        this.showLoaderAvailableParks = 1;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getParksForEventManagement?event_id=${this.eventId}&association_id=${this.association_id}`
        );

        if (response.data.statusCode === 200) {
          this.availableParks = Object.values(response.data.data.parks);
          this.showLoaderAvailableParks = 0;
        } else if (response.data.statusCode === 404) {
          this.closeModal();
          this.$alert(response.data.message, 'Error');
          this.$router.push({ name: "Teams" });
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoaderAvailableParks = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoaderAvailableParks = 0;
      }
    },
  },

  mounted() {
    this.$root.$on("openManageParksModal", () => {
      this.getPlayingFacilities();
      this.getPlayingFacilityForAssociation();
      var elem = this.$refs.openModalManageParks;
      if (elem) {
        elem.click();
      }
    });
  },
};
</script>
