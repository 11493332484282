<template>
  <div class="media-wrapper">
    <div v-if="showPinnedShimmer == 1" class="lds-dual-ring-media"></div>
    <div>
      <div class="link-content d-block pb-0 border-0">
        <span class="chat-filter-options mb-3" @click="showFilterOption">
          <span>Filter</span>
          <span class="filter-counter">{{
            Object.keys(this.filters).length
          }}</span>
          <img src="../../../../../assets/images/arrow-down.png" alt="" />
        </span>
        <div v-if="messages.length && showPinnedShimmer == 0">
          <div
            class="pinned-content"
            v-for="(file, index) in messages"
            :key="index"
          >
            <div class="top-sec">
              <div class="left-sec">
                <div v-if="file" class="left-sec-img">
                  <img
                    v-if="file.filesType == 2"
                    src="../../../../../assets/images/pinned-document.png"
                    alt=""
                    class="cursor-pointer"
                    @click="openInNewWindow(file.url)"
                  />

                  <photos-preview v-if="file.filesType == 1" :photo="file" />
                  <img
                    v-if="file.filesType == 3"
                    src="../../../../../assets/images/pinned-link.png"
                    alt=""
                    class="cursor-pointer"
                    @click="openInNewWindow(file.content)"
                  />
                </div>
                <div class="left-sec-content" v-if="file.filesType == 1">
                  <div class="name cursor-pointer" @click="openInNewWindow(file.url)">{{ file.tagsDetails.name }}</div>
                  <div class="type cursor-pointer" @click="openInNewWindow(file.url)">{{ file.name }}.{{ file.extension }}</div>
                  <EventGameName
                    :eventId="file.tagsDetails.eventId"
                    :gameId="file.tagsDetails.gameId"
                  />
                </div>
                <div class="left-sec-content" v-if="file.filesType == 2">
                  <div class="name cursor-pointer" @click="openInNewWindow(file.url)">{{ file.tagsDetails.name }}</div>
                  <div class="type cursor-pointer" @click="openInNewWindow(file.url)">{{ file.name }}.{{ file.extension }}</div>
                  <EventGameName
                    :eventId="file.tagsDetails.eventId"
                    :gameId="file.tagsDetails.gameId"
                  />
                </div>
                <div class="left-sec-content" v-if="file.filesType == 3">
                  <div class="name cursor-pointer"  @click="openInNewWindow(file.content)">{{ file.tagsDetails.name }}</div>
                  <div class="type cursor-pointer"  @click="openInNewWindow(file.content)">{{ file.content }}</div>
                  <EventGameName
                    :eventId="file.tagsDetails.eventId"
                    :gameId="file.tagsDetails.gameId"
                  />
                </div>
              </div>
              <div class="right-sec" v-if="file.senderId === currentUserId">
                <div class="" >
                  <img src="../../../../../assets/images/unpinned.png" alt="" @click="unpin(file)" />
                  <img
                    src="../../../../../assets/images/cross.png"
                    alt=""
                    @click="
                     deleteMessage({
                        message: file,
                        roomId: selectedRoomTeam.roomId,
                      })
                    "
                  />
                </div>
              </div>
            </div>
            <div class="bottom-sec">
              <div
                class="pin-filter"
                v-for="(tags, index) in file.tagsDetails.tags"
                :key="index"
              >
                <span>{{ tags }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="no-content"
      v-if="messages.length == 0 && showPinnedShimmer == 0"
    >
      <div v-if="showTeams == 0">
        <div class="img">
          <img src="../../../../../assets/images/pinned.png" alt="" />
        </div>
        <p class="header">No Pinned Media</p>
        <p class="text">All media that you marked pinned will appear here.</p>
      </div>
      <div v-if="showIndividual == 0">
        <div class="img">
          <img src="../../../../../assets/images/pinned.png" alt="" />
        </div>
        <p class="header">No Pinned Media</p>
        <p class="text">All media that you marked pinned will appear here.</p>
      </div>
    </div>
    <div class="popup" v-if="filterOption">
      <div class="blocker" @click="hidePopup()"></div>

      <div class="filter-options pinned-options">
        <div class="d-flex justify-content-end">
          <img
            src="../../../../../assets/images/cross.png"
            class="d-block d-lg-none cursor-pointer"
            @click="hidePopup()"
          />
        </div>
        <div>
          <label>Name</label>
          <input class="form-control" v-model="filter.name" />
        </div>
        <div>
          <label>Tag</label>
          <input class="form-control" v-model="filter.tags" />
        </div>

        <div class="form-group control-group">
          <label for="dateCheck">Date</label>
          <div
            class="input-group date"
            id="dateCheck"
            data-target-input="nearest"
          >
            <input
              class="form-control"
              placeholder=""
              required="required"
              id="startDate"
              type="date"
              v-model="filter.startDate"
            />
          </div>
        </div>

        <div v-if="selectedRoomTeam.chatType == 'team'">
          <label for="sportType">Event</label>
          <v-select
            v-model="filter.selectedEvent"
            :options="eventsAll"
            label="eventName"
            :placeholder="eventPlaceholder"
          />
        </div>
        <div class="event-btns-wrapper p-0 mt-4">
          <button
            v-if="this.isDisabledReset == false"
            type="button"
            class="btn btn-secondary w-50"
            @click="clearFilter(0)"
          >
            <span>Reset</span>
          </button>
          <button
            v-else-if="this.isDisabledReset == true"
            type="button"
            class="btn btn-secondary-disabled w-50"
            :disabled="true"
          >
            <span>Reset</span>
          </button>

          <button
            v-if="this.isDisabledFilter == false"
            type="button"
            class="btn btn-primary w-50"
            @click="getFilters(0)"
            :disabled="isLoadingArrayFilter[0]"
          >
            <div v-if="isLoadingArrayFilter[0]" class="lds-dual-ring"></div>
            <span v-else>Apply</span>
          </button>
          <button
            v-else-if="this.isDisabledFilter == true"
            type="button"
            class="btn btn-primary-disabled w-50"
            :disabled="(true, isLoadingArrayFilter[0])"
          >
            <div v-if="isLoadingArrayFilter[0]" class="lds-dual-ring"></div>
            <span v-else>Apply</span>
          </button>
        </div>
      </div>
      <div
        v-if="messages.length"
        v-observe-visibility="handleScrolledToButtom"
      ></div>
    </div>
  </div>
</template>

<script>
import * as firestoreService from "../../../../../database/firestore";
import { parseTimestamp } from "../../../../../utils/dates";
import PhotosPreview from "./PhotosPreview.vue";
import axios from "axios";
import EventGameName from "./EventGameName.vue";

export default {
  name: "ChatPinned",
  components: {
    PhotosPreview,
    EventGameName,
  },
  data() {
    return {
      showPinnedShimmer: 1,
      showTeams: 0,
      showIndividual: 1,
      filterOption: false,
      isDisabledReset: true,
      isDisabledFilter: true,
      isLoadingArrayFilter: [],
      tag: "",
      filter: {},
      filters: [],
      messagesLoaded: false,
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      messages: [],
      count: 0,
      eventsAll: [],
      eventPlaceholder: "Select event",
      messagesPerPage: 20,
    };
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
  },

  methods: {
    openInNewWindow(url) {
      window.open(url, '_blank'); // Open the media in a new tab
    },
    async deleteMessage({ message, roomId }) {
      await this.$confirm(
        "Are you sure you want to delete this message, Continue?",
        "Delete Message"
      ).then(() => {
        firestoreService.updateMessage(roomId, message._id, {
          deleted: new Date(),
        });

        this.$root.$emit("ChatPinned");
        if (this.selectedRoomTeam.lastMessage.id == message._id) {
          firestoreService.updateRoom(roomId, { last_message_sent: "" });
        }

        var deleteChatFiles = true;

        var deleteChatLinks = true;

        const { files } = message;

        if (files) {
          files.forEach((file) => {
            if (
              file.type == "image/jpeg" ||
              file.type == "audio/mpeg" ||
              file.type == "video/mp4"
            ) {
              deleteChatFiles = true;
            }

            if (
              file.type == "application/pdf" ||
              file.extension == "docx" ||
              file.type == "text/plain" ||
              file.extension == "sql"
            ) {
              deleteChatFiles = false;
            }
            var formData = new FormData();
            formData.append("files", file.url);
            axios
              .post(
                process.env.VUE_APP_API_URL + "chat/deleteMedia",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(() => {});
          });
          if (deleteChatFiles == true) {
            this.$root.$emit("ChatMedia");
            this.$root.$emit("ChatPinned");
          } else if (deleteChatFiles == false) {
            this.$root.$emit("ChatDocuments");
            this.$root.$emit("ChatPinned");
          }
        }
        if (deleteChatLinks == true) {
          if (this.validURL(message.content) == true)
            this.$root.$emit("ChatLinks");
          this.$root.$emit("ChatPinned");
        }
      });
    },
    async fetchMessagesForPined() {
      firestoreService
        .getPinnedMediaMessages(
          this.selectedRoomTeam.roomId,
          this.messagesPerPage,
          this.lastLoadedMessage
        )
        .then(async ({ data, docs }) => {
          const filePromises = data.map(async (message) => {
            if (message.files && !message.deleted) {
              let imageExt = [
                "jpg",
                "png",
                "PNG",
                "gif",
                "jpeg",
                "mp3",
                "mpeg",
                "mp4",
              ];
              const promises = message.files.map(async (file) => {
                const foundExt = imageExt.find(
                  (ext) => ext === file["extension"]
                );

                if (foundExt || file.audio) {
                  let filesType = 1;
                  const formattedMessage = await this.formatMessage(
                    this.selectedRoomTeam,
                    this.count,
                    file,
                    message,
                    filesType
                  );

                  this.messages[this.count] = formattedMessage;
                  this.messages = [...this.messages];
                  this.count++;
                } else if (!foundExt && !file.audio) {
                  let filesType = 2;
                  const formattedMessage = await this.formatMessage(
                    this.selectedRoomTeam,
                    this.count,
                    file,
                    message,
                    filesType
                  );
                  this.messages[this.count] = formattedMessage;
                  this.messages = [...this.messages];
                  this.count++;
                }
              });
              await Promise.all(promises);
            } else if (!message.deleted) {
              let filesType = 3;
              const formattedMessage = await this.formatMessage(
                this.selectedRoomTeam,
                this.count,
                message,
                message,
                filesType
              );
              this.messages[this.count] = formattedMessage;
              this.messages = [...this.messages];
              this.count++;
            }
          });
          await Promise.all(filePromises);
          this.showPinnedShimmer = 0;
          if (this.lastLoadedMessage) {
            this.previousLastLoadedMessage = this.lastLoadedMessage;
          }
          this.lastLoadedMessage = docs[docs.length - 1];
        });
        console.log(this.messages);
    },
    async fetchMessagesForPinedFilterted() {
      firestoreService
        .getPinnedMediaMessages(
          this.selectedRoomTeam.roomId,
          this.messagesPerPage,
          this.lastLoadedMessage,
          this.filter
        )
        .then(async ({ data, docs }) => {
          const filePromises = data.map(async (message) => {
            if (message.files && !message.deleted) {
              let imageExt = [
                "jpg",
                "png",
                "PNG",
                "gif",
                "jpeg",
                "mp3",
                "mpeg",
                "mp4",
              ];
              const promises = message.files.map(async (file) => {
                const foundExt = imageExt.find(
                  (ext) => ext === file["extension"]
                );

                if (foundExt || file.audio) {
                  let filesType = 1;
                  const formattedMessage = await this.formatMessage(
                    this.selectedRoomTeam,
                    this.count,
                    file,
                    message,
                    filesType
                  );

                  this.messages[this.count] = formattedMessage;
                  this.messages = [...this.messages];
                  this.count++;
                } else if (!foundExt && !file.audio) {
                  let filesType = 2;
                  const formattedMessage = await this.formatMessage(
                    this.selectedRoomTeam,
                    this.count,
                    file,
                    message,
                    filesType
                  );
                  this.messages[this.count] = formattedMessage;
                  this.messages = [...this.messages];
                  this.count++;
                }
              });
              await Promise.all(promises);
            } else if (!message.deleted) {
              let filesType = 3;
              const formattedMessage = await this.formatMessage(
                this.selectedRoomTeam,
                this.count,
                message,
                message,
                filesType
              );
              this.messages[this.count] = formattedMessage;
              this.messages = [...this.messages];
              this.count++;
            }
          });
          await Promise.all(filePromises);
          this.showPinnedShimmer = 0;
          if (this.lastLoadedMessage) {
            this.previousLastLoadedMessage = this.lastLoadedMessage;
          }
          this.lastLoadedMessage = docs[docs.length - 1];
        });
    },
    showFilterOption() {
      this.filterOption = !this.filterOption;
      if (
        this.filter.tags != null ||
        this.filter.selectedEvent != null ||
        this.filter.startDate != null ||
        this.filter.name != null
      ) {
        this.isDisabledReset = false;
      } else {
        this.isDisabledReset = true;
      }
    },

    hidePopup: function() {
      this.filterOption = false;
    },

    async unpin(file) {
      await this.$confirm(
        "Are you sure you want to unpin this media, Continue?",
        "Unpin Media"
      ).then(() => {
        const messageData = {
          pinFile: false,
          tagsDetails: {
            name: "",
            tags: "",
            eventId: "",
            gameId: "",
          },
        };
        firestoreService.updateMessageMedia(
          this.selectedRoomTeam.roomId,
          file._id,
          messageData
        );
        this.messages = [];
        this.count = 0;
        this.messagesLoaded = false;
        this.lastLoadedMessage = null;
        this.previousLastLoadedMessage = null;
        this.listeners = [];
        this.showPinnedShimmer = 1;
        this.fetchMessagesForPined();
      });
      this.filter = {};
      this.filters = [];
    },

    clearFilter: function() {
      this.messages = [];
      this.count = 0;
      this.messagesLoaded = false;
      this.lastLoadedMessage = null;
      this.previousLastLoadedMessage = null;
      this.listeners = [];
      this.filters = [];
      this.showPinnedShimmer = 1;
      this.fetchMessagesForPined();
      this.filter = {};
      this.filterOption = false;
      this.isDisabledFilter = true;
      this.isDisabledReset = true;
    },
    handleScrolledToButtom(isVisible) {
      // if (this.messagesPerPage <= this.lastLoadedMessage) {
      //   return;
      // }
      if ((this.previousLastLoadedMessage = this.lastLoadedMessage)) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.messagesPerPage++;

      this.fetchMessagesForPined();
    },
    getFilters(index) {
      this.$set(this.isLoadingArrayFilter, index, true);
      this.filters = Object.values(this.filter);
      this.lastLoadedMessage = null;
      this.previousLastLoadedMessage = null;
      this.messages = [];
      this.count = 0;
      this.fetchMessagesForPinedFilterted();
      this.showFilters = true;
      this.isDisabledFilter = true;
      this.$set(this.isLoadingArrayFilter, index, false);
      this.filterOption = false;
      this.isDisabledFilter = false;
      this.isDisabledReset = false;
    },
    formatMessage(room, count, file, message, filesType) {
      const formattedMessage = {
        ...file,
        ...{
          c_id: count,
          senderId: message.sender_id,
          _id: message.id,
          seconds: message.timestamp.seconds,
          timestamp: parseTimestamp(message.timestamp, "HH:mm"),
          date: parseTimestamp(message.timestamp, "DD MMMM YYYY"),
          username: room.users.find((user) => message.sender_id === user._id)
            ?.username,
          avatar: room.users.find((user) => message.sender_id === user._id)
            ?.avatar,
          distributed: true,
          filesType: filesType,
          tagsDetails: message.tagsDetails,
        },
      };
      console.log(formattedMessage);

      return formattedMessage;
    },

    async eventListing() {
      let searchResults = await axios.post(
        `event/eventListingFormedia?team_id=${this.selectedRoomTeam.roomId}`
      );
      if (searchResults.data.statusCode == 200) {
        this.eventsAll = searchResults.data.data;
      }
    },
  },
  watch: {
    // Watch for changes in the 'myObject' property
    filter: {
      handler() {
        if (
          this.filter.tags != null ||
          this.filter.selectedEvent != null ||
          this.filter.startDate != null ||
          this.filter.name != null
        ) {
          this.isDisabledFilter = false;
        } else {
          this.isDisabledReset = true;
        }
      },
      deep: true, // Watch for changes in nested properties of myObject
    },
  },
  mounted() {
    this.fetchMessagesForPined();
    this.eventListing();
    this.$root.$on("ChatPinned", () => {
      this.messages = [];
      this.lastLoadedMessage = null;
      this.previousLastLoadedMessage = null;
      this.messagesLoaded = false;
      this.count = 0;
      this.listeners = [];
      this.showPinnedShimmer = 1;
      this.fetchMessagesForPined();
    });
    // this.$root.$on("ChatPinned", () => {
    this.showPinnedShimmer = 1;
    // });
    if (localStorage.getItem("openTabChat") == "Individual") {
      this.showIndividual = 0;
      this.showTeams = 1;
    } else {
      this.showIndividual = 1;
      this.showTeams = 0;
    }
  },
};
</script>

<style>
.right-sec-img img{
  height: 16px!important;
}
</style>
