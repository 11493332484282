<template>
  <div>
    <button class="d-none" data-toggle="modal" data-target="#TournamentAddGame" ref="openModalTournamentAddGame"
      @click="dataReset()"></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="TournamentAddGame">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <h6 class="modal-title m-0">New Game</h6>
              <div class="products-list-shim p-0" v-if="showLoader == 1">
                <content-placeholders :rounded="true">
                  <content-placeholders-text :lines="1"/>
                </content-placeholders>
              </div>
              <span v-else class="text-capitalize">{{tournamentData?.event?.eventName}} - {{tournamentData?.tournamentName}}</span>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              ref="closeModalTournamentAddGame">
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="row no-gutters">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="typo__label" for="ajaxsingle">Game Type</label>
                  <select class="form-control form-control-lg" id="gameType" v-model="game_type" @change="errorNull">
                    <option value="" selected>Select game type</option>
                    <option value="1">Pool</option>
                    <option value="2">Bracket</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="name">Game Name</label>
              <input id="name" class="form-control" v-model="name" spellcheck="false" autocomplete="off" trim
                type="text" required @input="errorNull" />
            </div>

            <div class="row no-gutters">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label class="typo__label" for="ajaxsingle">Team 1</label>
                  <select class="form-control form-control-lg" id="teamOne" v-model="teamOne" @change="filterTeam"
                    :disabled="teamLoader">
                    <option value="" selected>No Team selected</option>
                    <option v-for="option in tournamentTeams" :value="option.team.id" :key="option.id">
                      {{ option.team.team_name }}
                    </option>
                  </select>
                  <div class="shop-products-img-loader" v-if="teamLoader">
                    <div class="lds-dual-ring-media"></div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 pl-md-2">
                <div class="form-group">
                  <label class="typo__label" for="ajaxsingle">Team 2</label>
                  <select class="form-control form-control-lg" id="teamTwo" v-model="teamTwo" :disabled="teamLoader">
                    <option value="" selected>No Team selected</option>
                    <option v-for="option in remainingTeams" :value="option.team.id" :key="option.id">
                      {{ option.team.team_name }}
                    </option>
                  </select>
                  <div class="shop-products-img-loader" v-if="teamLoader">
                    <div class="lds-dual-ring-media"></div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label class="typo__label" for="ajaxsingle">Umpire</label>
                  <select class="form-control form-control-lg" id="teamOne" v-model="umpire" @change="errorNull"
                    :disabled="isLoadingUmpire">
                    <option value="" selected>No umpire selected</option>
                    <option v-for="option in tournamentUmpire" :value="option.umpire.id" :key="option.umpire.id">
                      {{ option.umpire.name }}
                    </option>
                  </select>
                  <div class="shop-products-img-loader" v-if="isLoadingUmpire">
                    <div class="lds-dual-ring-media"></div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 pl-md-2">
                <div class="form-group">
                  <label for="startDateCheck">Start Date</label>
                  <span class="label-type error event-error ml-2 d-none" v-if="startDateError != ''">
                    Please Select Start Date.
                  </span>
                  <date-picker :open="open" @close="open = false" :editable="false" :clearable="false"
                    @click.native="handleClickStart" v-model="startDate" valueType="format" format="YYYY-MM-DD"
                    placeholder="yyyy-mm-dd" @change="checkdate">
                    <i slot="icon-calendar" class="cursor-pointer">
                      <img src="../../../assets/images/calendar-input.png" />
                    </i>
                  </date-picker>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 pr-md-1">
                <div class="form-group">
                  <label for="startTimeCheck">Start Time</label>
                  <span class="label-type error event-error ml-2" v-if="timeError != ''">
                    Event Time is Invalid.
                  </span>
                  <div class="input-group time" id="startTimeCheck" data-target-input="nearest">
                    <vue-timepicker id="startTime" class="time-input" placeholder="10:00 AM" format="hh:mm A"
                      required="required" v-model="startTime" v-on:change="checkTime" close-on-complete
                      hide-clear-button>
                      <template v-slot:icon>
                        <img src="../../../assets/images/clock-input.png" />
                      </template>
                    </vue-timepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="row my-2">
              <div class="col-sm-12 col-md-6 pl-md-2">
                <div class="">
                  <label class="typo__label" for="ajaxsingle">Park Name</label>
                  <multiselect v-model="partName" id="ajaxsingle" label="park_name" track-by="id"
                    placeholder="Type to search" open-direction="bottom" :options="parkNameOptions" :searchable="true"
                    :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="true"
                    :options-limit="300" :show-no-results="false" :hide-selected="true" @search-change="getGamePark"
                    @input="checkParkField" @select="dispatchAction">
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                        &nbsp;<span> {{ option.park_name }}</span>
                        <span class="custom__remove" v-on:click="remove(option)">❌</span>
                      </span>
                    </template>
                    <span slot="noResult">Oops! No Park found. Consider changing the search query.</span>
                    <span slot="noOptions"> No Park Found </span>
                    <template slot="option" slot-scope="{ option }">
                      <div class="option__desc">
                        <span> {{ option.park_name }}</span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 pl-md-2 mt-2 mt-md-0">
                <div class="">
                  <label class="typo__label" for="ajaxsingle">Field Name</label>
                  <multiselect v-model="parkField" id="ajaxsingle" label="field_name" track-by="id"
                    placeholder="Type to search" open-direction="bottom" :options="fieldNameOptions" :searchable="true"
                    :loading="isLoadingField" :internal-search="false" :clear-on-select="false" :close-on-select="true"
                    :options-limit="300" :show-no-results="false" :hide-selected="true" @search-change="getParkField"
                    @input="checkParkField" @select="dispatchfieldAction">
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="custom__tag">
                        &nbsp;<span> {{ option.field_name }}</span>
                        <span class="custom__remove" v-on:click="remove(option)">❌</span>
                      </span>
                    </template>
                    <span slot="noResult">Oops! No Field found. Consider changing the search query.</span>
                    <span slot="noOptions"> No Field Found </span>
                    <template slot="option" slot-scope="{ option }">
                      <div class="option__desc">
                        <span> {{ option.field_name }}</span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="note">Game Notes</label>
              <textarea type="text" class="form-control message" required rows="5" id="note" v-model="note"></textarea>
            </div>

            <a class="d-none" data-toggle="modal" data-target="#ModalPARK" ref="callPark" @click="closeMain">
              Add New Park
            </a>
            <a class="d-none" data-toggle="modal" data-target="#ModalField" ref="callField" @click="newField">
              Add New Field
            </a>
          </div>

          <div class="event-btns-wrapper p-3 justify-content-end">
            <button v-if="this.isDisabled == false" type="button" class="btn btn-primary w-100" block
              @click="addGame(0)" :disabled="isLoadingArray[0]">
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
            <button v-else-if="this.isDisabled == true" type="button" class="btn btn-primary-disabled w-100"
              :disabled="isLoadingArray[0]">
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <NewPark />
    <NewField />
  </div>
</template>
<script>
import axios from "axios";
import VueTimepicker from "vue2-timepicker";
import Multiselect from "vue-multiselect";
import NewPark from "../../Teams/Games/AddPark";
import NewField from "../../Teams/Games/AddField";
export default {
  name: "TournamentAddGameModal",
  components: {
    VueTimepicker,
    Multiselect,
    NewPark,
    NewField,
  },
  data() {
    return {
      name: "",
      teamOne: "",
      umpire: "",
      teamTwo: "",
      open: false,
      isLoadingArray: [],
      selectedParkId: "",
      userName: localStorage.getItem("userName"),
      isDisabled: true,
      partName: "",
      parkField: "",
      exactStartTime: "10:00 AM",
      exactStartDate: "",
      startDate: "",
      startTime: "10:00 AM",
      note: "",
      game_type: "",
      startDateError: "",
      exactDateformat: "",
      timeError: "",
      parkNameOptions: [],
      fieldNameOptions: [],
      tournamentTeams: [],
      tournamentUmpire: [],
      teamLoader: true,
      isLoading: false,
      isLoadingField: false,
      isLoadingUmpire: true,
      tabsTo: 0,
      teamSelect: [],
      tournamentGameDetail: [],
      showLoader: 0,
    };
  },
  computed: {
    // remainingTeams() {
    //   return this.tournamentTeams.filter(
    //     team => team.team.id !== this.teamOne
    //   );
    // }
    remainingTeams: {
      get() {
        return this.tournamentTeams.filter(
          team => team.team.id !== this.teamOne);
      },
      set(value) {
        this.teamSelect = value;
      }
    }
  },
  props: {
    tournamentData: {
      typeof: Object,
    },
    association_id: {
      type: Number,
    },
  },
  methods: {
    filterTeam() {
      this.remainingTeams = this.tournamentTeams.filter(
        team => team.team.id !== this.teamOne
      );
    },
    async getTournamentName() {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const tournamentGuid = segment_array.pop();
      this.showLoader = 1;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}tournaments/getSingleTournament`,
          {tournament_guid: tournamentGuid}
        );

        if (response.data.statusCode === 200) {
            this.tournamentData = response.data.data;
            // this.$root.$emit("tournmentSingleGameForTab", this.tournamentData )
            // this.$root.$emit("tournmentSingleGameForGame", this.tournamentData )
            this.showLoader = 0;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.showLoader = 0;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.showLoader = 0;
      } finally {
        this.showLoader = 0;
      }
  },
    dataReset() {
      this.getTournamentName();
      if (this.tabsTo == 0) {
        this.isDisabled = true;
        this.name = "";
        this.teamTwoId = "";
        this.teamOneId = "";
        this.teamOne = "";
        this.teamOne = "";
        this.umpire = "";
        this.game_type = "";
        this.teamTwo = "";
        this.partName = "";
        this.parkField = "";
        this.exactStartTime = "10:00 AM";
        this.exactStartDate = "";
        this.startDate = "";
        this.startTime = "10:00 AM";
        this.note = "";
        this.startDateError = "";
        this.exactDateformat = "";
        this.timeError = "";
        this.parkNameOptions = [];
        this.fieldNameOptions = [];
        this.getGamePark();
      } else {
        // this.partName = "";
        this.parkField = "";
      }
    },
    handleClickStart(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },

    checkdate: function () {
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Start Date
      if (this.startDate.length == 10) {
        const startDateArray = this.startDate.split("-");
        var startMonth = startDateArray[1];
        var startDay = startDateArray[2];
        var startYear = startDateArray[0];

        var varDate = startMonth + "-" + startDay + "-" + startYear; //dd-mm-YYYY
        var startDateConvert = new Date(varDate);
        this.exactStartDateformat = startDateConvert;
        this.exactStartDate = month[startMonth - 1] + " " + startDay + ", ";
      }

      this.checkTimeDate();
    },
    dispatchAction(actionName) {
      this.parkField = "";
      this.fieldNameOptions = [];
      switch (actionName.park_name) {
        case "Add Park":
          var tabs = 4;
          this.$root.$emit("AddParkInitiate", tabs);
          var elem = this.$refs.callPark;
          elem.click();
          break;
        default:
          // Assume that the actionName contains the selected park's ID
          this.selectedParkId = actionName.id;
          break;
      }
    },
    errorNull: function () {
      if (
        this.name == "" ||
        this.exactStartDate == "" ||
        this.exactStartTime == "" ||
        this.game_type == "" ||
        // this.teamOne == "" ||
        // this.umpire == "" ||
        this.partName == ""
        // || this.teamTwo == ""
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    closeMain() {
      this.$root.$emit("AddPark");
      var elem = this.$refs.closeModalTournamentAddGame;
      elem.click();
    },
    newField() {
      this.$root.$emit("AddField");
      var elem = this.$refs.closeModalTournamentAddGame;
      elem.click();
    },
    getTournamentTeams() {
      var url = window.location.pathname;
      var segment_array = url.split("/");
      const tournamentGuid = segment_array.pop();
      this.teamLoader = true;
      // Create a FormData object
      const formData = new FormData();
      formData.append('tournament_guid', tournamentGuid);
      formData.append('association_id', this.association_id);

      axios
        .post(
          `${process.env.VUE_APP_API_URL}tournaments/teams/getTournamentTeamsForDropDown`,
          formData, {})
        .then((response) => {
          this.tournamentTeams = response.data.data;
          this.teamLoader = false;
        });
    },
    async getGamePark() {
      try {
        this.isLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}associationEvent/getEventPlayingFacility?event_id=${this.tournamentData.event.id}&association_id=${this.association_id}`
        );
        if (response.data.statusCode === 200) {
          this.parkNameOptions = response.data.data.parkDetails;
          this.isLoading = false;
        } else {
          this.errorMessage = response.data.message || "Unknown error occurred";
          this.isLoading = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to fetch data";
        this.isLoading = false;
      }
    },
    getParkField(searchQuery) {
      this.isLoadingField = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getParkField?park_id=${this.partName.id
          }&search=${searchQuery ? searchQuery : ""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.fieldNameOptions = response.data.data.fields;
          this.isLoadingField = false;
        });
    },
    getTournamentUmpire() {
      this.isLoadingUmpire = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}associationEvent/getUmpiresForDropDown?event_id=${this.tournamentData.event.id}&association_id=${this.association_id}`,
          {}
        )
        .then((response) => {
          this.tournamentUmpire = response.data.data.umpires;
          this.isLoadingUmpire = false;
        });
    },
    checkParkField: function () {
      this.getParkField();
      this.errorNull();
    },
    dispatchfieldAction(actionName) {
      switch (actionName.field_name) {
        case "Add field":
          var tabs = 4;
          this.$root.$emit("AddFieldInitiate", {
            tabs,
            park_id: this.selectedParkId,
          });
          var elem = this.$refs.callField;
          elem.click();
          break;
      }
    },
    checkTime: function () {
      if (this.startTime != "") {
        var timeSplit = this.startTime.split(":"),
          hours,
          minutes;
        hours = timeSplit[0];
        minutes = timeSplit[1];
        if (hours > 12) {
          hours -= 12;
        } else if (hours < 12) {
          if (hours == 0) {
            hours = 12;
          }
        }
        this.exactStartTime = hours + ":" + minutes;
        this.timeError = "";
        this.isDisabledGame = false;
      }

      this.checkTimeDate();
    },
    closePark() {
      this.tabsTo = 0;
      var start_time = document.getElementById("startTime");
      if (start_time.value != "") {
        start_time.value = "";
      }
    },
    checkTimeDate() {
      this.startDateError = this.exactStartDate != "" ? "" : "on";
      if (this.exactStartTime != "" && this.exactStartDate != "") {
        this.exactDateformat = this.exactStartDate + " " + this.exactStartTime;
      }
      this.errorNull();
    },
    async addGame(index) {
      this.showLoaderGame = 1;
      this.isDisabledGame = true;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();

      formData.append("game_name", this.name ? this.name : "");
      formData.append("team_1_id", this.teamOne);
      formData.append("event_id", this.tournamentData.event.id);
      formData.append("team_2_id", this.teamTwo);
      formData.append("start_date", this.startDate);
      formData.append("start_time", this.startTime);
      formData.append("park_id", this.partName.id);
      formData.append("game_type", this.game_type);
      formData.append("comments", this.note ? this.note : "");
      formData.append('association_id', this.association_id);
      if (this.parkField != "") {
        formData.append(
          "field_id",
          this.parkField.id ? this.parkField.id : null
        );
      }
      formData.append("tournament_id", this.tournamentData.id);
      if(this.umpire === 0){
        formData.append("umpire_id", "");
      } else {
        formData.append("umpire_id", this.umpire ? this.umpire : "");
      }
      await axios
        .post(process.env.VUE_APP_API_URL + "tournaments/storeTournamentGames", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$root.$emit("RefreshTournamentGames");
          this.tabsTo = 0;
          var elem = this.$refs.closeModalTournamentAddGame;
          elem.click();
          this.showLoaderGame = 0;
          this.isDisabledGame = false;
          this.$set(this.isLoadingArray, index, false);

        });
    },
    openModal() {
      var elem = this.$refs.openModalTournamentAddGame;
      if (elem) {
        elem.click();
      }
      this.getTournamentTeams();
      this.getTournamentUmpire();
    },
  },

  mounted() {
    this.$root.$on("openTournamentAddGameModal", this.openModal);

    this.$root.$on("addedPark", (Tabs) => {
      this.tabsTo = Tabs;
      this.getGamePark();
      var elem = this.$refs.openModalTournamentAddGame;
      elem.click();
    });
    this.$root.$on("addTournamentGameField", (Tabs) => {
      this.tabsTo = Tabs;
      this.parkField = "";
      this.getParkField();
      var elem = this.$refs.openModalTournamentAddGame;
      elem.click();
    });
    this.$root.$on("checkMain", () => {
      this.closeMain();
    });
    // this.$root.$on("tournmentSingleGameForGame", (data) => {
    //   this.tournamentGameDetail = data;
    // });
  },
};
</script>
<style scoped>
.lds-dual-ring-media:after {
  width: 20px;
  height: 20px;
}

.shop-products-img-loader {
  position: absolute;
  top: 68%;
  left: 90% !important;
  z-index: 99;
}
@media screen and (max-width: 768px) {
  .modal-dialog .modal-content .modal-body{
    max-height: calc(100vh - 200px);
    overflow: scroll;
  }
}
</style>